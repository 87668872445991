import React, { useEffect, useState } from "react";
import image from "../../../assets/images/newMasjid.jpeg";
import { GetAppText } from "../../../utils";
import { useSelector } from "react-redux";
import { apiRoutes } from "../../../apis/routes";
import { getRequest } from "../../../apis/methods";
import { Loader } from "../../components/Loader";
const AboutUs = () => {
  const [loading,setLoading]=useState(false)
const[data,setData]=useState('')
useEffect(()=>{
  fetchData()
},[])

  const language = useSelector((state) => state.language.value);
  const Aboutdata = [
    {
      id: 1,
      heading: "",
      para: "مرحبًا بك في صفحة شروط الاستخدام والأحكام لتطبيق افعلوا الخير. يرجى قراءة هذه الشروط بعناية قبل استخدام التطبيق. باستخدام تطبيق افعلوا الخير، فإنك توافق على الامتثال لشروط وأحكام هذا الاتفاق، وتعتبر هذه الموافقة ملزمة قانونًا.",
    },
    {
      id: 2,
      heading: "رؤية ورسالة الخير",
      para: "نحن ففعلوا الخير نؤمن بأن الخير هو أساس بناء المجتمعات القوية والمتماسكة. نسعى جاهدين إلى ترسيخ قيم العطاء والتكافل في المجتمع من خلال تعزيز روح المسؤولية الاجتماعية. رؤيتنا تتمثل في خلق مجتمع يتشارك فيه الجميع في تحقيق الخير والازدهار. ونسعى إلى تمكين الأفراد والمؤسسات من تقديم المساعدة والدعم لمن هم في حاجة إليها، مع التركيز على القضايا الإنسانية الأساسية كالتعليم والصحة والبنية التحتية.",
    },
    {
      id: 3,
      heading: "دورنا في تعزيز التغيير",
      para: "نحن ندرك أن التغيير الحقيقي يبدأ من الأفعال البسيطة، ولهذا نعمل على تمكين الناس من اتخاذ خطوات ملموسة نحو تحسين حياة الآخرين. من خلال مشروعاتنا المختلفة، نسعى إلى إحداث تأثير إيجابي في حياة المستفيدين، سواء كان ذلك من خلال بناء المدارس، أو تقديم الرعاية الصحية، أو توفير المياه النظيفة. نؤمن بأن كل مشروع نقوم به يحمل في طياته قوة تغيير هائلة، وأن كل تبرع يمكن أن يحدث فرقًا كبيرًا.",
    },
    {
      id: 4,
      heading: "شراكاتنا ومساهماتكم",
      para: "ل بتعاون وثيق مع مختلف الشركاء المحليين والدوليين لضمان وصول المساعدات إلى من هم في أمس الحاجة إليها. ونحن نعتبر أن كل مسا البرامج الاستثمارية لدينا تتجاوز مجرد تقديم الخدمات، فهي تهدف إلى خلق بيئة تتيح للناس الفرصة للنمو والتطور والازدهار. كما أننا ملتزمون بالشفافية التامة في جميع أنشطتنا، ونعمل على توفير تقارير دورية تظهر أثر تبرعاتكم والمشاريع التي تم إنجازها بفضل دعمكم المستمر.",
    },
    {
      id: 5,
      heading: "لتزامنا بالجودة والشفافية",
      para: "في افعلوا الخير، نلتزم بأعلى معايير الجودة والشفافية في تنفيذ مشاريعنا. نحن ندرك أهمية الثقة التي يضعها المتبرعون فينا، لذا نحرص على تقديم تقارير تفصيلية ودقيقة حول جميع أنشطتنا ومشاريعنا. نسعى دائمًا إلى تحسين آليات العمل وتبني أحدث الابتكارات لضمان تحقيق أفضل النتائج. وبالإضافة إلى ذلك، نعمل على بناء شراكات استراتيجية مع منظمات وجمعيات موثوقة لضمان توجيه المساعدات إلى الأماكن الأكثر احتياجًا.",
    },
  ];


  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await getRequest(`${apiRoutes.get_about}`
       
        ); // Replace with your endpoint
if(response.status==="success")
   { 
    setLoading(false)
    setData(response?.data)
   

  }
  else{
    setLoading(false)
  }
    } catch (error) {
      setLoading(false)
    
    }
  };

  return (
    <div className="space-y-4">
      <div className="relative">
        <img src={image} className="w-full h-[430px] lg:object-fill object-cover " alt="" />
        <div className="absolute inset-0 bg-black opacity-30"></div>
        <div
          className={`flex items-center z-40 lg:bottom-20 bottom-10  absolute ${
            language === "_ar" ? "flex-row-reverse md:right-20 right-10" : "md:left-20 left-10"
          } text-white text-F40 font-bold `}
        >
          {GetAppText("about_us")}
        </div>
      </div>
      <div className=" md:px-20 px-8 md:py-16 py-8 w-full space-y-6">
        <h1
          className={`flex items-center  ${
            language === "_ar" ? "flex-row-reverse " : ""
          } text-primary text-F24 font-bold `}
        >
          {GetAppText("overview")}
        </h1>
      {loading?  <div className="flex h-[400px] w-full justify-center items-center">
 <Loader/>
 </div>
 :
 data?.value_ar || data?.value ?  <div className={`${language === "_ar" ?"text-right":"text-left"} bg-white p-6 flex-row-reverse rounded-xl`} dangerouslySetInnerHTML={{ __html: language === "_ar" ? data?.value_ar:data?.value }}>

        </div>
        :
        <div className="bg-white space-y-10 p-6 text-F18 font-semibold justify-center h-[400px] flex items-center rounded-xl">
 {GetAppText("no_data")}
        </div>
        
        }
    
        {/* <div className="bg-white space-y-10 p-6 flex-row-reverse rounded-xl">
          {Aboutdata &&
            Aboutdata?.map((item) => (
              <div className="space-y-3" key={item?.id}>
                <h1
                  className={`flex items-center  ${
                    language === "_ar" ? "flex-row-reverse " : ""
                  } text-black text-F16 font-bold `}
                >
                  {item?.heading}
                </h1>
                <div
                  className={`flex items-center  ${
                    language === "_ar" ? "flex-row-reverse  text-right" : ""
                  } text-primary text-F16 font-medium`}
                >
                  {item?.para}
                </div>
              </div>
            ))}
        </div> */}
      </div>
    </div>
  );
};

export default AboutUs;
