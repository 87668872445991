import { useState,useEffect } from 'react';
import { GetAppText, ValidationErrors } from "../../../utils";
import { useSelector,useDispatch } from "react-redux";
import { ModalComponent } from '../Modal';
import { ButtonComponent } from '../ButtonComponent';
import { SeparatorComponent } from '../Separator';
import { InputComponent } from '../InputComponent';
import { apiRoutes } from '../../../apis/routes';
import { getRequest, postRequest } from '../../../apis/methods';
import { modalHandler } from "../../../store/slices/modalSlice";
import { donationHandler } from "../../../store/slices/donationSlice";
import { useNavigate } from 'react-router-dom';
import { Image_URL } from '../../../utils/BaseUrl';
const DonationModal = ({ isAddModal,details,setIsAddModal,isHome=false}) => {
    const dispatch = useDispatch();
  const language = useSelector((state) => state.language.value);
  const isOpen = useSelector((state) => state.openModel.value);

  const [addloading,setAddLoading]=useState(false)
  const navigate = useNavigate();

const [price,setPrice]=useState(0)
useEffect(()=>{
  setPrice(details?.price)

},[])

const handleSubmit = async (item) => {
    // Prevent multiple submissions
  if (addloading) return; 
  // Prevent multiple submissions
  

    const data = {
      is_edit:true,
      cart_type:"CART",
      items: [
          {
              id:details?.itemable?.id,
              type:details?.itemable_type?.split('\\').pop().toUpperCase(),
              price: price
          }
      ]}

  if(price){
    setAddLoading(true)
if(isOpen==="isDonate"){
  try {
    const response = await postRequest(apiRoutes.add_donate, data); // Replace with your endpoint
   
 
    if (response?.success) { 
      setAddLoading(false)
      setIsAddModal(false)
      dispatch(modalHandler(""))
      // dispatch(donationHandler("Donate"))
      // localStorage.setItem('Donate',true)
      navigate(`/checkout/donation`);
      setPrice("")
 
    } else {
      setAddLoading(false)
      setIsAddModal(false)
      dispatch(modalHandler({ type: "toastError", msg: response?.data?.message}));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])
      setPrice("")
     
    }
  } 
  catch (error) {
  
  }

}
else
  {  
  
    try {
      const response = await postRequest(apiRoutes.add_cart, data); // Replace with your endpoint
   
   
      if (response.status === "success") { 
        setAddLoading(false)
        setIsAddModal(false)
        
        let error = '';
        if (
          response?.message?.includes(ValidationErrors.addCartItem.added)
        ) {
          error = GetAppText('added_cart');
        
        } 
        else if (
          response?.message?.includes(ValidationErrors.addCartItem.updated)
        ) {
          error = GetAppText('updated_cart');
         
        } 

        dispatch(modalHandler({ type: "toast", msg: error}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
  
        setPrice("")
   
      } else {
        setAddLoading(false)
        // setIsAddModal(false)

        let error=""
        if (
          response?.data?.message?.includes(ValidationErrors.addCartItem.limit_error)
        ) {
          // error = GetAppText('limit_price_cart');
          error = `${GetAppText('limit_price_cart')} ${item?.itemable?.remaining_goal_amount?.toLocaleString()}.`;
        } 
        else {
          // error = GetAppText('something_went_wrong');
          error= response?.data?.message
        }
        
        dispatch(modalHandler({ type: "toastError", msg: error}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
        // setPrice("")
       
      }
    } 
    catch (error) {
     
    }
  
  }
  
  }
    else{
        setAddLoading(false)
     
        dispatch(modalHandler("priceError"));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])

    }
  
  };


//   const inputRef = useRef("");

// useEffect(() => {
//   inputRef.current.focus();
// }, []);

  return (
<ModalComponent styles="!w-[900px]" isOpen={isAddModal} onClose={()=>{setIsAddModal(false);setPrice("")}}>
    
    {/* <Login className="" /> */}
    <div
        className={`bg-primary relative w-full rounded-lg sm:p-7 p-4 ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
   
      <div className="flex flex-col items-center justify-center space-y-9">
      <div
        className={`flex items-center ${
          language === "_ar" ? " " : "flex-row-reverse sm:space-x-reverse  space-x-reverse"
        } sm:space-x-10 space-x-4  w-full h-full justify-between`}
      >
<div className={`${details?.itemable_type?.split('\\').pop()==='Project' || details?.itemable_type?.split('\\').pop()==='Mosque'?'w-[65%]':' w-full  flex justify-center h-full'}   h-full`}>
        {/* {
          details?.media?.map((item,index)=>(
            index === 0 && <img src={item?.thumbnail} className={`object-cover  h-[300px] rounded-lg`} alt="logo" />
          ))
        } */}
        <img src={`${Image_URL}${details?.itemable?.image}`} className={`${details?.itemable_type?.split('\\').pop()==='Project' || details?.itemable_type?.split('\\').pop()==='Mosque'?' h-[300px]':'h-[50px]'} object-cover  rounded-lg`} alt="logo" />
</div>

      <div className="w-full text-white space-y-3">
          <div
            className={`flex ${
              language === "_ar" ? "flex-row-reverse " : ""
            } items-center justify-between`}
          >
            <h1 className="font-extrabold text-F24 ">{ language === "_ar" ?details?.itemable?.title_ar :details?.itemable?.title}</h1>
       
          </div>
          <div className={`relative sm:line-clamp-none line-clamp-6 font-normal ${language === "_ar" ?"text-right ":"text-left"}  text-F15`}>{language === "_ar" ?details?.itemable?.description_ar :details?.itemable?.description}</div>
     
          <SeparatorComponent className={"!border-borderSecondaryColor  !my-6"} />
         
          <div className=" w-full">
            <InputComponent   
            // ref={(el) => (inputRef.current = el)} 
            type="number" title="donation_amount" input_style={'!py-3 !text-black'} value={price}  onChange={(e) => setPrice(e.target.value)}/>
            </div>
   
        </div>
        </div>
        <div className="w-full"> 
            <div className="space-x-4 w-full flex items-center">
            <ButtonComponent disabled={addloading}  loading={addloading} styles={"!w-full !py-2.5"} onClick={()=>{handleSubmit(details)}} title={isOpen==="isDonate"?GetAppText("donate_now"):GetAppText("update")} type="secondary" />
              <ButtonComponent styles={"!w-full !py-2.5"} onClick={()=>{setIsAddModal(false);setPrice("")}} title={GetAppText("cancel")} type="transparent"/>
          
            </div>
          </div>
      </div>

    </div>
   
    </ModalComponent>
  );
};

export default DonationModal;
