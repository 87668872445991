import { getRequest, postRequest } from "./apis/methods";
import { apiRoutes } from "./apis/routes";
import "./App.css";
import { AppLayout } from "./views/appLayout";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { infoHandler } from "./store/slices/infoSlice";
import { settingHandler } from "./store/slices/settingsSlice";
import { countryHandler } from "./store/slices/countrySlice";
import countryData from "./assets/countryData.json";
const getDeviceFingerPrint = async () => {
  const fpPromise = await FingerprintJS.load();
  const result = await fpPromise.get();
  return result.visitorId;
};
const nAgt = navigator.userAgent;
const nVer = navigator.appVersion;
const getDeviceInfo = async () => {

  // Sample browser/device detection functions
  const getBrowserName = () => {
    const edgeRegex = /Edg\/([\d\.]+)/; // Updated regex to detect Edge
    const browsers = {
      Edge: edgeRegex, // Edge detection first
      Firefox: /(?:Firefox|FxiOS)\/([\d\.]+)/,
      Safari: /Version\/([\d\.]+).*Safari/,
      Chrome: /(?:Chrome|CrMo|CriOS)\/([\d\.]+)/,
      IE: /MSIE ([\d\.]+).*Trident/,
      Opera: /(?:Opera|OPR)\/([\d\.]+)/,
    };

    for (const browserName in browsers) {
      const match = nAgt.match(browsers[browserName]);
      if (match) {
        return browserName;
      }
    }
    return "Unknown";
  };

  const getDeviceType = () => {
    const isMobileUA = /Mobi|Android/i.test(nAgt);
    const isTabletUA = /Tablet|iPad/i.test(nAgt);
    const isDesktopUA = /Windows NT|Macintosh/i.test(nAgt);

    const screenWidth = window.screen.width;
    const isSmallScreen = screenWidth <= 768;

    if (isDesktopUA && !isSmallScreen) {
      return "Desktop";
    } else if (isTabletUA) {
      return "Tablet";
    } else if (isMobileUA || isSmallScreen) {
      return "Mobile";
    } else {
      return "Unknown";
    }
  };

  // system
  var os = "unknown";
  var clientStrings = [
    { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
    { s: "Windows Vista", r: /Windows NT 6.0/ },
    { s: "Windows Server 2003", r: /Windows NT 5.2/ },
    { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
    { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
    { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
    { s: "Windows 98", r: /(Windows 98|Win98)/ },
    { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
    { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: "Windows CE", r: /Windows CE/ },
    { s: "Windows 3.11", r: /Win16/ },
    { s: "Android", r: /Android/ },
    { s: "Open BSD", r: /OpenBSD/ },
    { s: "Sun OS", r: /SunOS/ },
    { s: "Linux", r: /(Linux|X11)/ },
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: "QNX", r: /QNX/ },
    { s: "UNIX", r: /UNIX/ },
    { s: "BeOS", r: /BeOS/ },
    { s: "OS/2", r: /OS\/2/ },
    {
      s: "Search Bot",
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];
  for (var id in clientStrings) {
    var cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }
  var osVersion = "Unknown";
  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1];
    os = "Windows";
  }
  switch (os) {
    case "Mac OS X":
      osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
      break;
    case "Android":
      osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
      break;
    case "iOS":
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
      osVersion = osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
      break;
  } 

  const fingerPrintId = await getDeviceFingerPrint();

  return {
    user_agent: nAgt,
    device_width: window.screen.width,
    device_height: window.screen.height,
    browser_name: getBrowserName(),
    browser_version: getBrowserVersion(),
    browser_language: navigator.language,
    device_type: getDeviceType(),
    fingerprint_id: fingerPrintId,
    // Add any other device information you want to track here
    is_mobile: /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(getBrowserName()),
    device_os: os,
    device_os_version: osVersion,
  };
};
function getBrowserVersion() {
  const userAgent = nAgt;
  const browsers = {
      Firefox: /(?:Firefox|FxiOS)\/([\d\.]+)/,
      Safari: /Version\/([\d\.]+).*Safari/,
      Chrome: /(?:Chrome|CrMo|CriOS)\/([\d\.]+)/,
      Edge: /Edge\/([\d\.]+)/,
      IE: /MSIE ([\d\.]+).*Trident/,
      Opera: /(?:Opera|OPR)\/([\d\.]+)/,
  };
  for (const browserName in browsers) {
      const match = userAgent.match(browsers[browserName]);
      if (match) {
          return match[1];
      }
  }
  return "Unknown";
}

function App() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.openModel.value);

useEffect(()=>{
  getSettings();
},[])

  useEffect(() => {

    getSync();
    fetchData();
 
   
    // getInit();
  }, [isOpen?.type === "initials"]);
  useEffect(() => {
    if( isOpen?.type === "updateToast" ||
      isOpen?.type === "updateToastError" ||
      isOpen?.type === "clear" || isOpen?.type==="updateOtpToast"||
      isOpen?.type === "initials")
{    getInit();
  }
  }, [
    isOpen?.type === "updateToast" ||
      isOpen?.type === "updateToastError" ||
      isOpen?.type === "clear" || isOpen?.type==="updateOtpToast"||
      isOpen?.type === "initials",
  ]);


  const getInit = async () => {
    try {
      const info = await getDeviceInfo();

      const response = await getRequest(apiRoutes.get_init); // Replace with your endpoint

      if (response.status==="success") {
   
        dispatch(infoHandler(response?.data));

      }
    } catch (error) {
      
    }
  };
  const getSettings = async () => {
    try {

      const response = await getRequest(apiRoutes.get_settings); // Replace with your endpoint

      if (response.status==="success") {
    
        dispatch(settingHandler(response));
      }
    } catch (error) {
      
    }
  };

  const getSync = async () => {
    try {
      const info = await getDeviceInfo();

      const payload = {
        fingerprint_id: info.fingerprint_id,
        app_version: "",
        device_os: info?.device_os,
        device_os_version: info?.device_os_version,
        device_type: info?.device_type,
        device_name: info?.browser_name,
        device_manufacturer: "",
        device_width: window.screen.width,
        device_height: window.screen.height,
        device_token: "", //fcm token
        ip_address: "",
        last_activity_at: "",
      };
      const response = await postRequest(apiRoutes.get_sync_device, payload); // Replace with your endpoint

      if (response.status==="success") {
   
        getInit()
      }
    } catch (error) {
     
    }
  };

  const fetchData = async () => {
    try {
      const response = await getRequest(apiRoutes.countries); // Replace with your endpoint
      if (response.status === "success") {
        const updatedCountries = response?.data.map((country) => {
          // Find the matching country in the countryData.json
          const matchingCountry = countryData.find(
            (item) => item.alpha_2 === country.country_code
          );

          // If a matching country is found, append the icon
          return matchingCountry
            ? { ...country, icon: matchingCountry.icon }
            : country;
        });
        dispatch(countryHandler(updatedCountries));
        // setCountryData(response?.data?.countries)
    
      } else {
        // setLoading(false)
      }
    } catch (error) {
      
    }
  };

  return (
    <div className="w-full h-full">
      <AppLayout />
    </div>
  );
}

export default App;
