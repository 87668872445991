import React, { useEffect, useRef, useState } from "react";
import { GetAppText } from "../../../../utils";
import { useSelector } from "react-redux";
import { NewsCardComponent } from "../../../components/NewsCardComponent";
import { Loader } from "../../../components/Loader";
import { useNavigate } from 'react-router-dom';
import "../../../../App.css"
import SvgComponent from "../../../components/SvgComponent";
export const HomeNews = ({data,loading}) => {
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);
  const dotsContainerRef = useRef(null);
  const scrollContainerRef = useRef(null);
  // const [scrollProgress, setScrollProgress] = useState(0); // Progress between dots
  // const handleScroll = (e) => {
  //   const scrollLeft = e.target.scrollLeft;
  //   const cardWidth = e.target.firstChild.offsetWidth; // Width of a single card
  //   const newIndex = Math.round(scrollLeft / cardWidth);
  //   setCurrentIndex(newIndex);
  // };

  const [dotIndex, setDotIndex] = useState(0); // Track which group of 3 dots to show
  // const dotsContainerRef = useRef(null);

  // const handleScroll = (e) => {
  //   const scrollLeft = e.target.scrollLeft;
  //   const cardWidth = e.target.firstChild.offsetWidth; // Width of a single card
  //   const newIndex = Math.round(scrollLeft / cardWidth);
  //   setCurrentIndex(newIndex);
    
  //   // Update the dot index to show the correct set of dots
  //   const newDotIndex = Math.floor(newIndex / 3);  // Group the dots in sets of 3
  //   setDotIndex(newDotIndex);
  // };
  const handleScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const cardWidth = e.target.firstChild.offsetWidth; // Width of a single card
    const totalCards = e.target.childNodes.length; // Total number of cards
    const newIndex = Math.round(scrollLeft / cardWidth);
  
    setCurrentIndex(newIndex);
  
    // Handle the dot group with a threshold for the last card
    const threshold = 0.1; // Adjust as needed (10% of card width)
    if (newIndex >= totalCards - 1 - threshold) {
      setDotIndex(Math.ceil((totalCards - 1) / 3) - 1); // Move to the last dot group
    } else {
      setDotIndex(Math.floor(newIndex / 3)); // Regular grouping logic
    }
  };

  const handleDotClick = (actualIndex) => {
    const cardWidth = scrollContainerRef.current.firstChild.offsetWidth;
    scrollContainerRef.current.scrollTo({
      left: actualIndex * cardWidth,
      behavior: "smooth",
    });
    setCurrentIndex(actualIndex); // Update active card
  };

  const handleProjectClick = (id) => {

    navigate(`/news/${id}`);
  };
  const handleReadMore = (id) => {

    navigate(`/news`);
  };
  
  const language = useSelector((state) => state.language.value);
  return (
    <div className="w-full h-full">
      <div>
        <div className={`flex  items-center ${
            language === "_ar" ? "flex-row-reverse" : ""
          } justify-between`}>
        <h1
          className={`text-primary text-F32 font-semibold ${
            language === "_ar" ? "text-right" : "text-left"
          }`}
        >
          {GetAppText("news")}
        </h1>
        <div
        onClick={()=>{handleReadMore()}}
        className={`flex  space-x-1 items-center ${
            language === "_ar" ? "flex-row-reverse space-x-reverse" : ""
          } justify-between`}>
        <h1
          className={`text-primary text-F18 font-semibold ${
            language === "_ar" ? "text-right" : "text-left"
          }`}
        >
         { GetAppText("read_more")}
        </h1>
        <SvgComponent
                name={language === "_ar" ? "ArrowLeft" : "ArrowRight"}
                className={"w-5 h-5"}
                fill={"none"}
                stroke={"#2E4049"}
              />
        </div>
        </div>
        <div className="pt-8">
          <div className={`flex flex-wrap ${
            language === "_ar" ? "flex-row-reverse " : ""
          } items-center  justify-center`}>

{ loading ?
         <div className="flex h-[420px] w-full justify-center items-center">
         <Loader/>
         </div>
         : 
              data?.length>0 ? 
              <>    
<div className={`hidden md:flex flex-wrap lg:gap-6 gap-4 ${
            language === "_ar" ? "flex-row-reverse " : ""
          } items-center  justify-center`}>
   {        data?.map((item,index)=>
 (<NewsCardComponent  onClick={()=>{handleProjectClick(item?.slug)}} key={index}  data={item}/>))  }
</div>

{/* Horizontal Scroll Container */}
<div
  className="md:hidden hide-scrollbar flex overflow-x-auto scroll-smooth snap-x snap-mandatory
  
  sm:space-x-12  space-x-4"
  onScroll={handleScroll}
  ref={scrollContainerRef}
>
  {data.map((item, index) => (
    <div
      key={index}
      className=""
    >
      <NewsCardComponent
        onClick={() => {
          handleProjectClick(item?.slug);
        }}
        data={item}
      />
     </div>
  ))}
</div>

 {/* Pagination Dots */}
 {/* <div className="mt-4">
      <div className="sm:hidden flex justify-center overflow-x-auto space-x-2" ref={dotsContainerRef}>
        {data.map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 rounded-full mx-1 p-2 transition-all ${
              currentIndex === index ? "bg-primary" : "bg-gray-300"
            }`}
          ></div>
        ))}
      </div>
    </div> */}
          <div className="mt-4">
  <div
    className="md:hidden flex justify-center overflow-x-auto space-x-2"
    ref={dotsContainerRef}
  >
    {/* Display 3 dots based on the current scroll position */}
    {data.slice(dotIndex * 3, dotIndex * 3 + 3).map((_, index) => {
      const actualIndex = dotIndex * 3 + index; // Actual index of the card
      return (
        <div
          key={index}
          className={`w-1 h-1 rounded-full mx-1 p-1.5 transition-all ${
            currentIndex === actualIndex ? "bg-primary" : "bg-gray-300"
          }`}

          onClick={() => handleDotClick(actualIndex)}
          
        ></div>
      );
    })}
  </div>
</div>

</>
:
(!loading && <div className="flex w-full h-[300px] justify-center text-F20 font-semibold items-center">
          {GetAppText("no_data")}
              </div>)}

        
          </div>
        </div>
      </div>
    </div>
  );
};
