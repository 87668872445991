import React, { useEffect, useRef, useState } from "react";
import { ButtonComponent } from "../../components/ButtonComponent";
import { GetAppText, ValidationErrors } from "../../../utils";
import { useSelector,useDispatch } from "react-redux";
import SvgComponent from "../../components/SvgComponent";
import { SeparatorComponent } from "../../components/Separator";
import { ReactComponent as Bpay } from "../../../assets/svgs/Bpay.svg";
import { ReactComponent as Applepay } from "../../../assets/svgs/applePay.svg";
import { ReactComponent as Visa } from "../../../assets/svgs/visa.svg";
import Signup from "../Signup";
import { ReactComponent as UnfillCheckbox } from "../../../assets/svgs/checkboxUnfill.svg";
import { ReactComponent as FillCheckbox } from "../../../assets/svgs/checkboxFill.svg";
import { ReactComponent as PayCheck } from "../../../assets/svgs/userExists.svg";
import { ReactComponent as UserExists } from "../../../assets/svgs/userExists.svg";
import { ReactComponent as PayCross } from "../../../assets/svgs/paymentCross.svg";
import { InputComponent } from "../../components/InputComponent";
import { ModalComponent } from "../../components/Modal";
import { useNavigate } from 'react-router-dom';
import { Login } from "../Login";
import { Otp } from "../Otp";
import { useParams,useLocation } from 'react-router-dom';
import { modalHandler } from "../../../store/slices/modalSlice";
import { ResetPassword } from "../ResetPassword";
import { getRequest, postRequest } from "../../../apis/methods";
import { apiRoutes } from "../../../apis/routes";
import { Loader } from "../../components/Loader";
import { handleClick, handleSuccess } from "../../components/Toast/toastHelper";
import { ToastContainer } from "react-toastify";
import { Image_URL } from "../../../utils/BaseUrl";
import Dropdown from "../../components/Dropdown";
const Checkout = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const inputRef = useRef(null);
    const language = useSelector((state) => state.language.value);
    const isOpen = useSelector((state) => state.openModel.value);
    const isDonate = useSelector((state) => state.donation.value);
    const invoiceData = useSelector((state) => state.data.value);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isloginModal, setIsloginModal] = useState(false);
    const [isSignupnModal, setIsSignupModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [isOtpModal, setIsOtpModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isResetModal, setIsResetModal] = useState(false);
    const countryData = useSelector((state) => state.country.value);
    const[digitLenght,setDigitLenght]=useState(8)
    const[token,setToken]=useState("")
    const[count,setCount]=useState("")
useEffect(()=>{
  if(isOpen=="LoginModal")
{  setIsloginModal(false)
  setIsSignupModal(false)
  setIsOtpModal(false)
}
  else if(isOpen=="SignupModal"){
    setIsloginModal(false)
    setIsSignupModal(true)
    setIsOtpModal(false)
  }
  else if(isOpen=="OtpModal"){
    setIsloginModal(false)
    setIsSignupModal(false)
    setIsOtpModal(true)
  }
  else if(isOpen=="ResetModal"){
    setIsloginModal(false)
    setIsSignupModal(false)
    setIsOtpModal(false)
    setIsResetModal(true)
  }

},
[isOpen])

useEffect(()=>{

  const storedValue = localStorage.getItem("token");
  setToken(storedValue)
},[isOpen?.type==="initials"])

const[selected,setSelected]=useState('')

const payment=[
    {id:1,name:GetAppText("apple"),svg:<Applepay/>,},
    {id:2,name:GetAppText("credit"),svg:<Visa/>},
    {id:3,name:GetAppText("debit_card"),svg:<Bpay/>},
]
const hadith="مَنْ دَلَّ عَلَى خَيْرٍ فَلَهُ مِثْلُ أَجْرِ فَاعِلِهِ (حديث نبوي)"

const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cvv, setCVV] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const[data,setData]=useState('')
  const[totalprice,setTotalPrice]=useState('')
  const [loading,setLoading]=useState(false)
  const [donateLoading,setDonateLoading]=useState(false)
  const [paymentLoading,setPaymentLoading]=useState(false)
  const [paymentData,setPaymentData]=useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('+973');
  const [selectedValue2, setSelectedValue2] = useState(1);
  const { id } = useParams();

  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const queryParams = new URLSearchParams(location.search);

  // Extract the desired parts
  const donation = pathParts.includes("donation") ? "donation" : "cart";
  const status = queryParams.get("status");

useEffect(()=>{
  if(status==='success')
 { setIsModalOpen(true)
  setPaymentSuccess(true)
 }
 else if(status==='failed'){
  setIsModalOpen(true)
  setPaymentSuccess(false)
  fetchData()
 }
},[status])
useEffect(() => {
  if (isOpen?.type === "toast") {
    handleSuccess(isOpen?.msg, language);
  } else if (isOpen?.type === "toastError") {
    handleClick(isOpen?.msg, language);
  } 
}, [isOpen]);

const handle_terms=()=>{
  navigate(`/terms-condition`);
}
  const handleLogin=()=>{
      // navigate(`/login`);
      setShowModal(false)
      setIsloginModal(true)
  }
  const handleSignup=()=>{
    // navigate(`/signup`);
    setIsSignupModal(true)
}
useEffect(()=>{
  fetchPaymentType()
  const isDonate = localStorage.getItem('Donate');

    fetchData()

},[isOpen?.type==="initials"]) 

const fetchData = async () => {

  setLoading(true)

let data={}
if (donation==="donation") {
  data["type"] = "DONATION";
}

  try {
    const response = await getRequest(`${apiRoutes.get_cart}`,data
    
      ); // Replace with your endpoint
if(response.status === "success")
 { 
  setLoading(false)
  setData(response?.data?.cart)
  setCount(response?.data?.count)
  setTotalPrice(response?.data?.total)

}
else{
  setLoading(false)
}
  } catch (error) {
    setLoading(false)
    console.error('Error fetching data:', error);
  }
};

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);




  const fetchPaymentType = async () => {
    setPaymentLoading(true)
    try {
      const response = await getRequest(apiRoutes.get_payment); // Replace with your endpoint
if(response.status==="success")
   { 
    setPaymentLoading(false)
    setPaymentData(response?.data)
    // setData(response?.data)

  }
  else{
    setPaymentLoading(false)
  }
    } catch (error) {
      setPaymentLoading(false)
      console.error('Error fetching data:', error);
    }
  };




  const donate_handler = async () => {
    const formattedPhone = Number(selectedValue.replace('+', ''));

    const number=Number(phoneNumber)
    const combinedPhone = `${formattedPhone}${number}`; 
    setDonateLoading(true)
    const payload = {
      cart_type:donation?.toUpperCase(),
      cart_id:data?.id,
      payment_method_id:selected,
      apple_token:'',
      name:cardName,
email:email,
country_id:selectedValue2,
phone_number: Number(combinedPhone),
is_guest:showModal
      // paymentId:selected,
      // cardName: cardName,
      // cardNumber:cardNumber,
      // CVV: cvv,
      // expiryDate: `${expiryMonth}/${expiryYear}`,
    };
    
    try {
      const response = await postRequest(apiRoutes.checkout,payload); // Replace with your endpoint
console.log("Respnonse",response)
      if(response.status==="success")
   { 

    setDonateLoading(false)
  // setRedirect(url)
  if(response?.data?.url)
  {window.location.href=response?.data?.url

  }

  }

  else {
    let error=""
    setDonateLoading(false) 
    
            if (response?.data?.message?.includes(ValidationErrors?.email?.invalid)) {
              error=GetAppText('email_must_be_valid');
            } else if (
              response?.data?.message?.includes(ValidationErrors?.email?.alreadyExists)
            ) {
              // error=GetAppText('email_already_exists');
              setShowModal(true)
            } 
            else if (
              response?.data?.message?.includes(ValidationErrors?.phoneNumber?.alreadyExists)
            ) {
              setShowModal(true)
              // error=GetAppText('phone_already_exists');
            } 
            else if (
              response?.data?.message?.includes(ValidationErrors?.phoneNumber?.alreadyTaken)
            ) {
              setShowModal(true)
              // error=GetAppText('phone_already_exists');
            } 
            else if (
              response?.data?.message?.includes(ValidationErrors?.phoneNumber?.notComplete)
            ) {
              const str = response.message.trim().replace(/\.$/, '');
              const lastNumber = str.match(/\d+$/)?.[0];
              let phError = GetAppText('phone_not_valid') + ' ' + lastNumber;
              error=phError;
            }
    


            if(error)
        {  
          dispatch(modalHandler({type:"toastError",msg:error
            // GetAppText("req_payment")
          }));
          setTimeout(()=>{
              dispatch(modalHandler(""));
            },[500])
          }


  // else{
  //   setDonateLoading(false)
  //   console.log("Response",response)
  //   dispatch(modalHandler({type:"toastError",msg:response.data.message
  //     // GetAppText("req_payment")
  //   }));
  //   setTimeout(()=>{
  //       dispatch(modalHandler(""));
  //     },[500])
  // }
    } 
  }
    catch (error) {
      setDonateLoading(false)
      console.error('Error fetching data:', error);
    }
  };

  const handler=()=>{

    setIsDropdownOpen(!isDropdownOpen);
  }
  const handleSelect = (value) => {
    setDigitLenght(value?.phone_digits)
    setSelectedValue(value?.phone_code);
    setSelectedValue2(value?.id)
    setPhoneNumber('')
    setIsDropdownOpen(false);
    inputRef.current.focus();
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
  
    // Allow only numbers and limit to 8 digits
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    if (numericValue.length <= digitLenght) {
      setPhoneNumber(numericValue);
    }
  };
  
  const handleKeyDown = (event) => {
    if (["e", "E", ".", "-", "+"].includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      // handleSubmit(); // Call the handleSubmit function when Enter key is pressed
    }
  };
  

  return (
    <>
      <ToastContainer/>
    <div className="md:px-20 sm:px-10 px-5 sm:py-16 py-10 w-full space-y-6">
    
<div className={`w-full flex sm:space-y-0 space-y-3 sm:flex-row flex-col sm:items-center ${language === "_ar"
          ? "sm:flex-row-reverse items-end"
          : "items-start"
        }  sm:justify-between `}>
        <div className={`flex ${language === "_ar"
            ? ""
            : "flex-row-reverse space-x-reverse"
          }     items-center space-x-2`}>
   
          <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-primary text-F24 font-bold `}
          >
            {GetAppText("payment_page")}
          </h2>
        </div>
  {!token &&     <div className={`flex items-center space-x-3 ${language === "_ar"
            ? "flex-row-reverse space-x-reverse"
            : "flex-row"
          }    `}>

          <ButtonComponent title={GetAppText("create_account")} onClick={handleSignup} styles={" px-5 whitespace-nowrap !py-2.5  text-F16"} type="primary" />
          <ButtonComponent title={GetAppText("login")}  onClick={handleLogin} styles={" px-5 whitespace-nowrap  text-F16 !py-2.5"} type="secondary_transparent" />
        </div>}

      </div>
      <div className={`w-full md:space-x-11 sm:space-x-5 flex sm:flex-row flex-col-reverse sm:space-y-0 space-y-5 space-y-reverse sm:items-start ${language === "_ar"
          ? "sm:flex-row-reverse md:space-x-reverse sm:space-x-reverse" 
          : ""
        }  justify-between `}>
          {/* Payment Summary */}
        <div className={`w-full bg-white rounded-xl p-6 flex-col items-center `}>
            <div className={`w-full flex items-center   ${language === "_ar"
            ? "flex-row-reverse"
            : ""
          }  justify-between `}>
          <div className={`flex ${language === "_ar"
              ? ""
              : "flex-row-reverse space-x-reverse"
            }     items-center space-x-2`}>
          {/* {count>0&&  
          <ButtonComponent title={count} styles={"!py-0.5 !px-2 whitespace-nowrap   text-F14"} type="primary" />} */}
            <h2
              className={`flex items-center ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                } text-primary text-F16 font-bold `}
            >
              {GetAppText("donation_summary")}
            </h2>
          </div>
      {id!=="donation"&& count>0&& 
      <div onClick={()=>{navigate(`/donars-bag`);}} className={`flex items-center cursor-pointer space-x-1 ${language === "_ar"
              ? ""
              : "flex-row-reverse space-x-reverse space-x-2"
            }    `}>
  
  <SvgComponent
                        name={"Edit"}
                        className={"w-4 h-4"}
                        fill="none"
                        stroke={"#E49E4E"}
                      />
          <h2
              className={`flex items-center hover:underline ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                } text-secondary text-F14 font-bold `}
            >
              {GetAppText("edit")}
            </h2>
          </div>}
  
        </div>
        <div className="pt-5 pb-2 space-y-3.5">
       
      {
       loading? <div className="flex justify-center items-center h-[80px]">
       <Loader/>
       </div>
      
      :data?.items?.length>0 ? data?.items?.map((item)=>(
        <div key={item?.id} className={`flex ${language === "_ar"
            ? ""
            : "flex-row-reverse space-x-reverse"
          }  justify-between   items-center space-x-2`}>
          <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
              } text-secondaryTextColor text-F13 font-semibold  space-x-1`}
          >
            <div>
            {item?.price?.toLocaleString()}</div> <div>{GetAppText("bhd")}</div>
          </h2>
          <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-black text-F13 font-semibold  `}
          >
            {/* {item?.itemable?.} */}
            { language === "_ar" ?item?.itemable?.title_ar:item?.itemable?.title}
          </h2>

        </div>
      ))
      
      :
      (!loading&&<div className="flex justify-center items-center h-[80px]">
      {GetAppText("no_data")}
      </div>)
      }
      </div>

       {!loading&& data ? <SeparatorComponent className="!my-2.5"/>:''}
{  totalprice!==0 &&      <div className={`flex ${language === "_ar"
                ? ""
                : "flex-row-reverse space-x-reverse"
              }  justify-between   items-center space-x-2`}>
              <h2
                className={`flex items-center ${language === "_ar"
                    ? "flex-row-reverse space-x-reverse"
                    : ""
                  } text-black text-F14 font-bold  space-x-1`}
              >
                <div>
                  {totalprice?.toLocaleString()}</div> <div className="text-F14">{GetAppText("bhd")}</div>
              </h2>
              <h2
                className={`flex items-center ${language === "_ar"
                    ? "flex-row-reverse  space-x-reverse"
                    : ""
                  } text-black text-F14 font-semibold  `}
              >
                {GetAppText("total")}
              </h2>

            </div>}
        
        </div>

        {/* Payment Method */}
        <div className={`md:w-7/12 sm:w-8/12 w-full bg-white rounded-xl p-6 space-y-2  items-center ${language === "_ar"
          ? "flex-row-reverse"
          : ""
        }  `}>
            <div>
 <h2
              className={`flex items-center ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                } text-primary text-F16 font-bold `}
            >
              {GetAppText("payment_method")}
            </h2>
            <div className={`pt-5  pb-2 space-y-3`}>
            {paymentLoading?
            <div className="flex justify-center py-2">
            <Loader style={'!w-6 !h-6'}/>
            </div>
            :
            
            paymentData.length>0 && paymentData?.map((item)=>(
              <div key={item?.id} className={`flex ${language === "_ar"
                  ? "flex-row-reverse space-x-reverse"
                  : ""
                }     items-center space-x-3.5`}>
                <h2
                  className={`flex items-center ${language === "_ar"
                      ? "flex-row-reverse space-x-reverse"
                      : ""
                    } text-secondaryTextColor text-F13 font-semibold  space-x-1`}
                >
                  <div className="cursor-pointer" onClick={()=>{setSelected(item?.id)}}>{item?.id==selected?<FillCheckbox/>:<UnfillCheckbox/>}</div>
                </h2>
                <h2
                  className={`flex items-center ${language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                    } text-black text-F14 font-semibold  `}
                >
                  {language === "_ar"?item?.name_ar:item?.name}
                </h2>
      <div>
          {item.svg}
      </div>
      <img src={`${Image_URL}${item?.image}`}/>
              </div>
            ))}  
     
      </div>
      </div>
 {!token &&      <div className="space-y-4 pt-6">
      <h2
              className={`flex items-center ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                } text-primary text-F16 font-bold `}
            >
              {GetAppText("card_info")}
            </h2>
            <div className="space-y-5 ">
            <div className="space-y-2">
            <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
              } text-black text-F12 font-semibold  space-x-1`}
          >
           {GetAppText("name")}
          </h2>
<InputComponent input_style={'!py-3'} value={cardName} onChange={(e) => setCardName(e.target.value)}/>
</div>

<div className="space-y-2">
            <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
              } text-black text-F12 font-semibold  space-x-1`}
          >
           {GetAppText("email")}
          </h2>
<InputComponent input_style={'!py-3'} value={email} onChange={(e) => setEmail(e.target.value)}/>
</div>

<div className="space-y-2 relative">
            <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
              } text-black text-F12 font-semibold  space-x-1`}
          >
           {GetAppText("phone")}
          </h2>
          <div>
          <div className="flex items-center  py-1 w-full bg-white border-2 border-borderColor rounded-lg ">
            <div onClick={handler} className="flex cursor-pointer items-center px-2.5 space-x-2 border-e border-e-borderColor">
              <div className="text-sm">
                {selectedValue}
              </div>
            <SvgComponent
                      name={"ChevronDown"}
                      className={`w-2.5 h-1.5`}
                      stroke={"black"}
                      fill={"none"}
                    />
            </div>
                          <input
                           onKeyDown={handleKeyDown}
                    type={"number"}
                    id={"phone"}
                    value={phoneNumber}
                    onChange={handleInputChange}
                    className={`block no-spinner w-full border-none focus:border-borderColor focus:outline-none focus:ring-0  placeholder:text-F14 text-sm placeholder:text-lightTextColor ${
                      language === "_ar" ? "pe-2 text-right" : "ps-3"
                    } `}
                   
                    name={"phone"}
                    ref={inputRef}
                  />
                        
                  </div>
                  {isDropdownOpen&& <Dropdown phone={true} mainStyle={`w-full mt-14 !h-[200px]`}  handleSelect={handleSelect}  isOpen={isDropdownOpen} options={countryData} setIsDropdownOpen={setIsDropdownOpen} />}
                
                  </div>
{/* <InputComponent input_style={'!py-3'}  value={cardNumber} onChange={(e) => setCardNumber(e.target.value)} /> */}
</div>
{/* <div className="flex items-center space-x-4 justify-between">
<div className="space-y-2 w-full">
            <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
              } text-black text-F12 font-semibold  space-x-1`}
          >
       (CVV)  {GetAppText("security_code")} 
          </h2>
<InputComponent input_style={'!py-3'} value={cvv} onChange={(e) => setCVV(e.target.value)} />
</div>
<div className="space-y-2 w-full">
            <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
              } text-black text-F12 font-semibold  space-x-1`}
          >
           {GetAppText("expiry_date")}
          </h2>
          <div className="flex items-center">
<InputComponent isTranslate={false} title="mm"   value={expiryMonth}
                onChange={(e) => setExpiryMonth(e.target.value)} input_style={'!py-3 !text-center !rounded-s-lg !border-r-0 !rounded-none'}/>
<InputComponent  isTranslate={false} title="yy"   value={expiryYear}
                onChange={(e) => setExpiryYear(e.target.value)} input_style={'!py-3 !text-center !rounded-e-lg !rounded-none'}/>
</div>
</div>
</div> */}
</div> 

</div> }
 
<div className="space-y-3 pt-7">
    <div className={`flex whitespace-nowrap 
       ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                }
       items-center justify-center space-x-2`}>
    <h2
              className={`flex items-center ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                } text-secondaryTextColor text-F12 font-semibold `}
            >
              {GetAppText("continue")}
            </h2>
            <h2
onClick={handle_terms}
              className={`flex hover:underline hover:cursor-pointer  items-center ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                } text-black text-F12 font-semibold  underline`}
            >
              {GetAppText("terms_use_conditions")}
            </h2>
    </div>
{!token?<ButtonComponent loading={donateLoading} disabled={donateLoading ||  !selected ||  !cardName || !email || !phoneNumber} onClick={donate_handler} title={GetAppText("move_pay")} styles={"!py-2.5 w-full px-4 whitespace-nowrap   text-F16"} type="secondary" />
:<ButtonComponent loading={donateLoading} disabled={donateLoading || !selected} onClick={donate_handler} title={GetAppText("move_pay")} styles={"!py-2.5 w-full px-4 whitespace-nowrap   text-F16"} type="secondary" />

}

</div>
        </div>
      </div>
    
    </div>
    <ModalComponent styles="!w-[460px]" isOpen={isloginModal} onClose={()=>{setIsloginModal(false);dispatch(modalHandler(""));}}>
    
      <Login className="" />
     
      </ModalComponent>

      {/* <ModalComponent styles="!w-[460px]" isOpen={isOtpModal} onClose={()=>{setIsOtpModal(false);dispatch(modalHandler(""));}}>
    <Otp/>
    </ModalComponent> */}


      <ModalComponent styles="!w-[900px]" isOpen={isSignupnModal} onClose={()=>{setIsSignupModal(false);dispatch(modalHandler(""));}}>
    
    <Signup className="" />
   
    </ModalComponent>
    
    <ModalComponent styles="!w-[460px]" isOpen={isResetModal} onClose={()=>{setIsResetModal(false);dispatch(modalHandler(""));}}>
 <ResetPassword/>
    </ModalComponent>

    {/* Modal */}
    <ModalComponent isOpen={isModalOpen} onClose={closeModal} paymentModal={true}>
      <div className="relative flex justify-center w-full ">
      <div className="absolute top-0 right-0">
        <img src={require("../../../assets/images/Vector.png")}className="h-44 w-44" alt="logo" />
      </div>
      
        <div className=" flex flex-col items-center pt-12 pb-7 px-7 space-y-4">
         {paymentSuccess? <PayCheck/> :<PayCross/>}
          <h2
              className={`flex items-center text-center ${language === "_ar"
                  ? "flex-row-reverse"
                  : ""
                } text-white text-F20 font-bold `}
            >
              {paymentSuccess? GetAppText("donation_successful"):GetAppText("payment_failed")}
            </h2>
            <h2
              className={`flex items-center text-center ${language === "_ar"
                  ? "flex-row-reverse  "
                  : ""
                } text-modelTextColor text-F17 font-light `}
            >
              {paymentSuccess? GetAppText("donation_success_msg"):GetAppText("donation_failed_msg")}
            </h2>
           {paymentSuccess && <h2
              className={`flex items-center py-4 text-center ${language === "_ar"
                  ? "flex-row-reverse  "
                  : ""
                } text-secondary text-F19 font-normal `}
            >
              {hadith}
            </h2>}
            <div className={`flex items-center w-full space-x-3 ${language === "_ar"
            ? "flex-row-reverse space-x-reverse"
            : "flex-row"
          }    `}>

   { !paymentSuccess   &&   <ButtonComponent title={GetAppText("retry")} styles={"py-3 px-4 whitespace-nowrap w-full  text-F16"} type="secondary"
          onClick={()=>{
        
            setIsModalOpen(false)
           

          }}
          />}
          <ButtonComponent title={GetAppText("back_to_main")} styles={"py-2.5 px-4 whitespace-nowrap w-full text-F16 "} 
          onClick={()=>{setIsModalOpen(false);
            navigate('/')
          }} type="transparent" />
        </div>

        </div>
        </div>
     
      </ModalComponent>

 { showModal &&  
 <ModalComponent isOpen={showModal} onClose={()=>{setShowModal(false);dispatch(modalHandler(""));}} >
      <div className="relative flex justify-center w-full ">
      <div className="absolute top-0 right-0">
        <img src={require("../../../assets/images/Vector.png")}className="h-44 w-44" alt="logo" />
      </div>
      
        <div className=" flex flex-col items-center space-y-4 w-full pt-12 pb-7 px-6 ">
        <UserExists/>
         <div className="space-y-8 w-full flex flex-col items-center justify-center">
       <div className="text-center flex flex-col items-center">
            <h2
              className={`flex items-center text-center ${language === "_ar"
                  ? "flex-row-reverse  "
                  : ""
                } text-modelTextColor text-F16 font-bold `}
            >
              { GetAppText("user_exits")}
            </h2>
            <h2
              className={`flex items-center text-center ${language === "_ar"
                  ? "flex-row-reverse  "
                  : ""
                } text-modelTextColor text-F16 font-bold `}
            >
              { GetAppText("user_exits_1")}
            </h2>
            </div>
            <div className={`flex items-center  w-full space-x-3 ${language === "_ar"
            ? "flex-row-reverse space-x-reverse"
            : "flex-row"
          }    `}>
          <ButtonComponent
          loading={donateLoading}
           title={GetAppText("continue_guest")} styles={"py-2.5 px-4 whitespace-nowrap !w-full text-F16 "} 
          onClick={()=>{ 
            donate_handler()
            // setShowModal(false);
          }} type="transparent" />
<ButtonComponent title={GetAppText("login")} styles={"py-3 px-4 whitespace-nowrap !w-full  text-F16"} type="secondary"
          onClick={()=>{
            handleLogin()
           

          }}
          />

        </div>
        </div>
        </div>
        </div>
     
      </ModalComponent>}
    </>
  );
};

export default Checkout;
