import React from "react";
import { useSelector } from "react-redux";
export const ProgressBar = ({
  value = 45,
  progressStyle = {},
  mainStyle = {},
}) => {
  const language = useSelector((state) => state.language.value);
  return (
    <div
      className={`w-full flex ${
        language === "_ar" ? "flex-row-reverse " : ""
      } bg-btn_gray rounded-full ${mainStyle}`}
    >
      {
        value==0 ?
        <div className=" text-xs text-gray-800  flex justify-center w-full text-center">
          {Math.round(value)} % 
          </div>:
            <div
            className={`bg-primary  text-xs font-F11 
              ${value<=70 ?` ${
              language === "_ar" ? "text-left " : "text-right"
            }   ` : "text-center" }
              
             text-white   md:p-0.5 p-1.5 leading-none rounded-full ${progressStyle}`}
            style={{ width: `${value?.toString()}%` }}
          > {Math.round(value)}% 
          </div>
      }
    
    </div>
  );
};
