import React, { useEffect, useState } from "react";
import { GetAppText } from "../../../utils";
import { MyAccount } from "./MyAccount";
import { SunniEndowments } from "./SunniEndowments";
import { HelpSupport } from "./HelpSupport";
import SvgComponent from "../../components/SvgComponent";
import { useSelector,useDispatch } from "react-redux";
import { StyleClass } from "../../../utils";
import { ReactComponent as IOS } from "../../../assets/svgs/apple.svg";
import { ReactComponent as Android } from "../../../assets/svgs/android.svg";
import { ReactComponent as Gallery } from "../../../assets/svgs/gallery.svg";
import {ReactComponent as Texture} from "../../../assets/svgs/texture5.svg"
import { Sidebar } from "./Sidebar";
import { PersonalInformation } from "./MyAccount/PersonalInformation";
import { AccountSettings } from "./MyAccount/AccountSettings";
import { DonationRecord } from "./MyAccount/DonationRecord";
import Faqs from "./Faqs";
import ContactUs from "./Contact_us";
import { AboutSunniEndowments } from "./SunniEndowments/AboutSunniEndowments";
import { TermsOfUse } from "./SunniEndowments/TermsOfUse";
import { PrivacyPolicy } from "./SunniEndowments/PrivacyPolicy";
import { ModalComponent } from "../../components/Modal";

import { modalHandler } from "../../../store/slices/modalSlice";
import { ButtonComponent } from "../../components/ButtonComponent";
import { useNavigate } from 'react-router-dom';
import { apiRoutes } from "../../../apis/routes";
import { getRequest, postRequest } from "../../../apis/methods";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleClick, handleSuccess } from '../../components/Toast/toastHelper';
import { useAuth } from "../../../context/authContext";
export const Profile = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth(); 
  const navigate = useNavigate();
  const [isSidebarToggle, setSideBarToggle] = useState(false);
  const [islogoutModal, setIslogoutModal] = useState(false);
  const selectedOption = useSelector((state) => state.selectedOption.value);
  const data = useSelector((state) => state.info.value);
  const language = useSelector((state) => state.language.value);
  const isOpen = useSelector((state) => state.openModel.value);
  const countryData = useSelector((state) => state.country.value);
  const settingData = useSelector((state) => state.setting.value);
  // const[data,setData]=useState('')
  // const[countryData,setCountryData]=useState('')

  const IosInfo = settingData?.data?.find(
    item => item.name === 'IOS_STORE_LINK',
  );

  const androidInfo = settingData?.data?.find(
    item => item.name === 'ANDROID_STORE_LINK',
  );



  const app = [
    { id: 1, name: "App Gallery", img: <Gallery />,link:androidInfo?.value },
    { id: 2, name: "Android", img: <Android />,link:androidInfo?.value },
    { id: 3, name: "IOS", img: <IOS />,link:IosInfo?.value },
  ];
  useEffect(()=>{
    
    if(isOpen?.type==="updateToast"){
      handleSuccess(isOpen?.msg,language)
      // fetchData()
    }
    else if(isOpen?.type==="updateToastError"){
      handleClick(isOpen?.msg,language)
      // fetchData()
    }
    else if(isOpen==="Error"){
      handleClick("All field are required",language)
    }
    else if(isOpen==="LogoutModal"){
      setIslogoutModal(true)
    }
   else if(isOpen?.type==="updateOtpToast"){
     
      handleSuccess(isOpen?.msg,language)

    }
  },[isOpen])

  const handleStore=(item)=>{
    window.open(item?.link, "_blank")
  }


  const toggleSideBarHandler = (val) => {
    setSideBarToggle(val.value);
  };

  const renderProfileComponent = () => {
    switch (selectedOption) {
      case 1:
        return <PersonalInformation data={data?.user} countrydata={countryData}/>;
      case 2:
        return <AccountSettings />;
      case 3:
        return <DonationRecord />;
      case 4:
        return <AboutSunniEndowments />;
      case 5:
        return <TermsOfUse />;
      case 6:
        return <PrivacyPolicy />;
      case 7:
        return <Faqs />;
      case 8:
        return <ContactUs data={data?.user}/>;
      default:
        return <MyAccount />;
    }
  };

  const renderSettings = () => {
    switch (selectedOption) {
      case 1:
        return "Personal information";
      case 2:
        return "Account settings";
      case 3:
        return "Donation record";
      case 4:
        return "About us";
      case 5:
        return "Terms of use";
      case 6:
        return "Privacy policy";
      case 7:
        return "Faqs" ;
      case 8:
        return "Contact us";
      // default:
      //   return <MyAccount />;
    }
  };
  const renderSettingsType = () => {

if(selectedOption===1 || selectedOption===2 || selectedOption===3 )
{
  return "My accounts"
}
if(selectedOption===4 || selectedOption===5 || selectedOption===6 )
  {
    return "Sunni endowments"
  }
  if(selectedOption===7 || selectedOption===8 )
    {
      return "Help and support"
    }
  
  };

const handleSubmit=async()=>{

try{
  const response = await postRequest(apiRoutes.logout);
  if(response.status==="success"){
    navigate("/");
    logout()
    dispatch(modalHandler({ type: "clear", msg:""}));
  }
}
catch (error) {
  
  console.error('Error fetching data:', error);
}


  // localStorage.clear()
}
const getFirstLetter = (str) => {
  return str ? str.charAt(0).toUpperCase() : '';
};

const navigateFunc=()=>{
  window.location.reload();
}
  return (
    <>
              <ToastContainer />
    <div className="w-full h-full">

      {isSidebarToggle ? (
        <div className="sm:py-16 py-10 lg:px-20 sm:px-8 px-4  h-full w-full">
          <div className={`flex md:space-x-8 space-x-4  ${
                        language === "_ar" ? " md:space-x-reverse" : ""
                      } ${StyleClass()}`}>
            <div className="lg:w-[35%] md:w-7/12  md:flex hidden">
              <Sidebar  data={data?.user}/>
            </div>
            <div className="w-full">
            <div className="md:hidden pb-10 pt-4 space-x-3 font-semibold text-secondaryTextColor text-F16 flex items-center">
              <div onClick={navigateFunc}>
              {"Settings"}
              </div> 
              <div>
              {">"}
              </div> 
              <div onClick={navigateFunc} >
                {renderSettingsType()}
            
              </div> 
              <div>
              {">"}
              </div> 
              <div className="text-secondary">
              {renderSettings()}
              </div> 
            </div>
            <div className="  w-full ">{renderProfileComponent()}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full  h-full">
          <div className="w-full bg-custom-gradient-background justify-center sm:py-12 py-24">
            <div className="flex items-center justify-center">
              <div className="space-y-4 items-center justify-center flex flex-col">
                <div className="w-16 h-16 rounded-full bg-secondary flex items-center justify-center">
                  <span className="font-bold text-F26 text-white">{getFirstLetter(data?.user?.name)}</span>
                </div>
                <h1 className="text-white text-F27 font-bold">
                  {/* {GetAppText("test_user_name")} */}
                  {data?.user?.name ? data?.user?.name:"N/A"}
                  {/* {settingData?.data[16]?.name} */}
                </h1>
                <span className="text-white text-F20">
                  {data?.user?.country?.phone_code} {data?.user?.phone_number}
                  </span>
              </div>
            </div>
          </div>
          <div className="w-full h-full flex justify-center items-center">
            <div className="md:w-8/12 w-full py-12 md:px-0 px-5">
              <div className="space-y-12 ">
                <MyAccount toggleSideBar={(val) => toggleSideBarHandler(val)} />
                <SunniEndowments
                  toggleSideBar={(val) => toggleSideBarHandler(val)}
                />
                <HelpSupport
                  toggleSideBar={(val) => toggleSideBarHandler(val)}
                />
                <div   onClick={()=>{setIslogoutModal(true)}} className="p-4 bg-white rounded-md cursor-pointer">
                  <div
                
                    className={`flex items-center space-x-3 ${StyleClass()}`}
                  >
                    <SvgComponent
                      name={"Logout"}
                      className={"w-5 h-5"}
                      stroke={"#E94747"}
                      fill={"none"}
                    />
                    <span
                      className={`text-errorColor text-F16 ${
                        language === "_ar" ? "text-right" : "text-left"
                      } font-semibold`}
                    >
                      {GetAppText("logout")}
                    </span>
                  </div>
                </div>
                <div className="bg-custom-gradient-background px-6 py-8 rounded-md">
                  <div
                    className={`sm:flex sm:space-y-0 space-y-4 items-center justify-between ${StyleClass()}`}
                  >
                    <div className="space-y-2 ">
                    <h1 className={`font-bold text-secondary  ${
                        language === "_ar" ? "text-right" : "text-left"
                      } sm:text-F20 text-F16`}>
                      {GetAppText("download_app_now")}
                    </h1>
                    <h1 className={`font-normal text-white  ${
                        language === "_ar" ? "text-right" : "text-left"
                      } sm:text-F16 text-F14`}>
                      {GetAppText("donate_app")}
                    </h1>
</div>

                    <div className="flex flex-wrap gap-3">
                      {app &&
                        app?.map((item) => (
                          <div
                          onClick={()=>{handleStore(item)}}
                            key={item?.id}
                            className="flex items-center cursor-pointer space-x-3 lg:text-F16 sm:text-F13 text-F16 text-white px-4 py-1.5 border-white rounded-md border-2 "
                          >
                            <div>{item.img}</div>
                            <div>{item.name}</div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      )}
        <ModalComponent styles="!w-[720px]" isOpen={islogoutModal} onClose={()=>{setIslogoutModal(false);dispatch(modalHandler(""));}}>
    
    {/* <Login className="" /> */}
    <div
        className={`bg-primary relative w-full rounded-lg p-7 pt-10 ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
         <div className="absolute z-50 top-0 right-0">
     <Texture className="object-cover"/>
      </div>
      <div className="flex flex-col items-center justify-center space-y-8">
        <div className="flex flex-col items-center justify-center space-y-3">
        <div className="rounded-full bg-white p-4">
        <SvgComponent
                      name={"Logout"}
                      className={"w-7 h-7"}
                      stroke={"#E94747"}
                      fill={"none"}
                    />
        </div>
        <div className={`text-white text-center font-bold text-F22`}>
          {GetAppText("logout_msg")}
        </div>
        </div>
        <div className="w-full"> 
            <div className="space-x-4 w-full flex items-center">
              <ButtonComponent styles={"!w-full !py-2.5"} onClick={()=>{setIslogoutModal(false);dispatch(modalHandler(""))}} title={GetAppText("cancel")} type="transparent"/>
              <ButtonComponent styles={"!w-full !py-2.5 !bg-errorColor !border-errorColor"} onClick={handleSubmit} title={GetAppText("logout")}  />
            </div>
          </div>
      </div>

    </div>
   
    </ModalComponent>
    </div>
    </>
  );
};
