import React, { useEffect, useState } from "react";
import { GetAppText } from "../../../utils";
import { useSelector,useDispatch } from "react-redux";
import { AccordionItem } from "../../components/Accordian";
import { ReactComponent as Message } from "../../../assets/svgs/message.svg";
import { ReactComponent as Phone } from "../../../assets/svgs/phoneCircle.svg";
import { ReactComponent as Whatsapp } from "../../../assets/svgs/whatsapp.svg";
import { ButtonComponent } from "../../components/ButtonComponent";
import { GoogleMap, useJsApiLoader,MarkerF,DirectionsRenderer } from '@react-google-maps/api';
import { apiRoutes } from "../../../apis/routes";
import { getRequest } from "../../../apis/methods";
import { Loader } from "../../components/Loader";


const containerStyle = {
  width: '100%',
  height: '340px',
    borderRadius:"12px"
};

const center = {
  lat:26.211034409555996,
  lng: 50.57099908443672
};

const TechnicalSupport = () => {

useEffect(()=>{
  fetchData()
},[])


  const markerPosition = { lat:26.211034409555996,
    lng: 50.57099908443672}; 

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBvvRJkCY7OYQo5QGhSzqHl0FCC5uYRUQE"
  })

  const [loading, setLoading] = useState(false);
  // const [map, setMap] = useState(null)

  // const onLoad = React.useCallback(function callback(map) {
  //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);

  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  const language = useSelector((state) => state.language.value);


const data =[
  {id:1,name:"example@example.com",svgName:<Message/>},
  {id:2,name:"+973 1700 0000",svgName:<Phone/>},
  {id:3,name:"+973 3600 0000",svgName:<Whatsapp/>},
]

  const [openIndex, setOpenIndex] = useState(null);
  const categories = [
    { type: 'GENERAL_QUESTIONS', title: language === '_ar' ? 'الأسئلة العامة' : 'General Questions' },
    { type: 'DONATION_AND_PROJECTS', title: language === '_ar' ? 'التبرعات والمشاريع' : 'Donation and Projects' },
    { type: 'ACCOUNT_AND_REGISTRATION', title: language === '_ar' ? 'الحساب والتسجيل': 'Account and Registration' },
  ];

  const [faqloading,setfaqLoading]=useState(false)
  const[faqdata,setFaqData]=useState('')

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const mapOptions = {
    mapTypeControl: false, // Disable the map and satellite options
    fullscreenControl: false, // Optionally disable fullscreen control
    streetViewControl: false, // Optionally disable Street View control
  };

  const [directions, setDirections] = useState(null);

  const getDirections = () => {
  
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLoading(true); // Start loading
        const origin = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        const directionsService = new window.google.maps.DirectionsService();

        // Simulate a delay for loading demonstration
       
          directionsService.route(
            {
              origin: origin,
              destination: markerPosition,
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);
              } else {
                console.error(`Error fetching directions ${result}`);
              }
              setLoading(false); // Stop loading
            }
          );
     
      });
    }
  };
  const settingData = useSelector((state) => state?.setting?.value);
  const fetchData = async () => {
    setfaqLoading(true)
    try {
      const response = await getRequest(`${apiRoutes.get_faqs}`
       
        ); // Replace with your endpoint
if(response.status==="success")
   { 
    setfaqLoading(false)
    setFaqData(response?.data)

  }
  else{
    setfaqLoading(false)
  }
    } catch (error) {
      setfaqLoading(false)
      console.error('Error fetching data:', error);
    }
  };

  const whatsAppInfo = settingData?.data?.find(
    item => item.name === 'WHATSAPP',
  );
  const webEmail = settingData?.data?.find(
    item => item.name === 'WEBSITE_EMAIL',
  );
  const webPhone = settingData?.data?.find(
    item => item.name === 'WEBSITE_PHONE_NUMBER',
  );
  const location = settingData?.data?.find(
    item => item.name === 'OFFICE_LOCATION',
  );
  return (
    <div className={` md:px-20 px-8 md:py-16 py-8 w-full space-y-16`}>
<div className="space-y-6">
<h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-primary text-F24 font-bold `}
          >
            {GetAppText("technical_support")}
          </h2>
          <div   className={`flex md:flex-nowrap md:space-y-0 space-y-4 flex-wrap items-start md:space-x-5 ${language === "_ar"
                ? "flex-row-reverse  md:space-x-reverse"
                : ""
              }  `}>
                 <div className="w-full bg-offWhite  p-6 rounded-xl space-y-6">
          <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-secondaryTextColor text-F18 font-semibold `}
          >
            {GetAppText("our_website")}
          </h2>
          <div style={{ height: '340px', width: '100%' }}>
      
    {  isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={18}
        // onLoad={onLoad}
        options={mapOptions}
        // onUnmount={onUnmount}
      >
         <MarkerF position={{lat:26.211034409555996,
  lng: 50.57099908443672}} />
    {directions && <DirectionsRenderer directions={directions} />}
        { /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>
  ) : <></>}
    </div>


       <div   className={`sm:flex sm:space-y-0 space-y-3 items-center justify-between ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              }  `}>
       <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse text-right space-x-reverse"
                : ""
              } text-black text-F16 font-medium `}
          >
            {language === "_ar"?location?.value_ar:location?.value}
          
          </h2>
          <ButtonComponent  onClick={getDirections} styles={"!px-6 sm:!w-fit w-full !text-F18"} title={loading ? GetAppText('calculating') : GetAppText('get_direction')} type="secondary_transparent"/>
       </div>
            </div>
          <div className="md:w-4/12 w-full bg-offWhite  p-6 rounded-xl space-y-6 ">
          <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-secondaryTextColor text-F18 font-semibold `}
          >
            {GetAppText("contact_us")}
          </h2>
          <div  className={`flex flex-col   gap-6 items-center justify-between ${language === "_ar"
                ? ""
                : "-flex-row-reverse  space-x-reverse"
              } `}>
    
           <a 
               href={`mailto:${webEmail?.value}`}
           className={`flex hover:opacity-85  w-full text-[#303036] text-F16 space-x-2 border border-borderColor p-3 rounded-lg items-center 
           `}>
            <div>
            <Message/>
</div>
<div>
{webEmail?.value} 
</div>


      </a>
      <a
         href={`tel:${webPhone?.value}`}
      className={`flex hover:opacity-85 w-full text-[#303036] text-F16 space-x-2 border border-borderColor p-3 rounded-lg items-center 
           `}>
            <div>
            <Phone/>
</div>
<div>
{webPhone?.value} 
</div>


      </a>
      <a
       href={`https://wa.me/${whatsAppInfo?.value}`}
       target="_blank"
      className={`flex hover:opacity-85 w-full text-[#303036] text-F16 space-x-2 border border-borderColor p-3 rounded-lg items-center 
           `}>
            <div>
            <Whatsapp/>
</div>
<div>
{whatsAppInfo?.value} 
{/* {data.phone_number} */}
</div>


      </a>
        </div>
        {/* {  data&& data?.map((item)=>(
          <div key={item.id} className={`flex p-3 text-[#303036] text-F16 space-x-2 border border-borderColor rounded-lg items-center ${language === "_ar"
            ? ""
            : ""
          } `}>
            <div>
{item.svgName}
</div>
<div>
{item.name}
</div>


      </div>
        ))} */}
            </div>
           
          </div>
</div>


  


 { faqdata &&    <div className="space-y-6">
     <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-primary text-F24 font-bold `}
          >
            {GetAppText("frequently_asked_questions")}
          </h2>

{
 faqloading ?
 <div className="flex h-[440px] w-full justify-center items-center">
 <Loader/>
 </div>
:
// faqdata && faqdata?.map((items,index)=>{
//   // const keyName = Object.keys(items)[0]; 
//   return(
//     <div key={index} className="w-full bg-offWhite p-6 rounded-xl space-y-7">
//     <h2
//           className={`flex   items-center ${language === "_ar"
//               ? "flex-row-reverse  space-x-reverse"
//               : ""
//             } text-secondary text-F20 font-bold `}
//         >
//           {/* {keyName} */}
//               </h2>

//   </div>
//   )

// })
<div className="space-y-5">
{/* <div className="w-full bg-offWhite p-6 rounded-xl space-y-7"> */}
      {/* Loading state */}
      {/* {faqLoading && <p>Loading FAQs...</p>} */}

      { faqdata.length > 0 && categories.map((category) => {
        const categoryFaqs = faqdata?.filter((item) => item.type === category.type); // Filter by category type

        return (
          categoryFaqs.length > 0 && (
         
            <div key={category.type} className="w-full bg-offWhite p-6 rounded-xl space-y-7">
              {/* Category Heading */}
              <h2 className={`text-2xl ${language==="_ar"?'text-right text-F20':'text-F18'} font-bold text-secondary`}>
                {category.title}
              </h2>

              {/* FAQs under the current category */}
              {categoryFaqs.map((item, index) => (
                <AccordionItem
                  key={index}
                  index={index}
                  title={language === "_ar" ? item.question_ar : item.question}
                  content={language === "_ar" ? item.answer_ar : item.answer}
                  isOpen={openIndex === item?.id}
                  onToggle={() => toggleAccordion(item?.id)}
                />
              ))}
            </div>
         
          )
        );
      })}
    </div>
// </div>
    }
    {/* <div className="w-full bg-offWhite p-6 rounded-xl space-y-7">
      <h2
            className={`flex  items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-secondary text-F20 font-bold `}
          >
            الحساب والتسجيل	
          </h2>
          <div className="space-y-5">
      {accordionData2.map((item, index) => (
        <AccordionItem
          key={index}
          index={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex2 === index}
          onToggle={toggleAccordion2}
        />
      ))}
    </div>
    </div> */}
    </div>}
    </div>
  );
};

export default TechnicalSupport;
