import React, { useEffect, useState } from "react";
import { Achievement } from "./Acheivement";
import { AboutDonations } from "./AboutDonations";
import { HomeNews } from "./HomeNews";
import { ProjectsEndingSoon } from "./ProjectsEndingSoon";
import { useSelector } from "react-redux";
import UrgentProjects  from "./UrgentProjects";
import CustomSwiper from "./SliderComponent";
import { getRequest } from "../../../apis/methods";
import { apiRoutes } from "../../../apis/routes";
import { ToastContainer } from 'react-toastify';
import {ReactComponent as Texture} from "../../../assets/svgs/texture3.svg"
import 'react-toastify/dist/ReactToastify.css';
import { handleClick, handleSuccess } from '../../components/Toast/toastHelper';
import QuickDonate from "../../components/QuickDonation";
import "../../../App.css"

import { ReactComponent as Arrow } from "../../../assets/svgs/crossArrow.svg";
import { GetAppText } from "../../../utils";
import SvgComponent from "../../components/SvgComponent";
const Home = () => {
  const [loading,setLoading]=useState(false)
  const[data,setData]=useState('')
  const isOpen = useSelector((state) => state.openModel.value);
  const language = useSelector((state) => state.language.value);
  const details = useSelector((state) => state.info.value);

  useEffect(()=>{
    if(isOpen?.type==="toast"){
      closeModal()
      handleSuccess(isOpen?.msg,language)
    }
    else if(isOpen?.type==="toastError"){
      closeModal()
      handleClick(isOpen?.msg,language)
    }
    else if(isOpen==="priceError"){
      handleClick(GetAppText("req_price"),language)
    }
    else if(isOpen==="fieldError"){
      handleClick("Please select a required option.",language)
    }
    else if(isOpen?.type==="share"){
 
      handleSuccess(isOpen?.msg,language)
    }
  },[isOpen])


useEffect(()=>{
  fetchData()
  
},[])

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await getRequest(apiRoutes.get_home); // Replace with your endpoint
if(response.status==="success")
   { 
    setLoading(false)
    setData(response?.data)
    
  }
  else{
    setLoading(false)
  }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
    setTimeout(() => setIsModalOpen(true), 50); // Slight delay for fade-in effect
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => setShowModal(false), 300);
     // Delay to allow fade-out transition
  };


  return (
    <div className="w-full h-full pb-16">
      <div className="flex flex-col">
      <ToastContainer />
        <div className="w-full h-full">
          <CustomSwiper data={data?.sliders} loading={loading}/>
        </div>
        <div>
        <div>
      {/* Floating Button */}
      <div className="relative">
 {  <button className={`float-btn sm:text-F24 text-F17 hover:bg-[#233138] z-40 flex items-center sm:bottom-2 bottom-3 bg-primary sm:gap-4 gap-3 sm:h-16 h-14 sm:px-7 px-4 
 ${language === "_ar" ? "sm:left-10 left-3" : "sm:right-10 right-3"
                  }`} onClick={openModal}>
                    <div>
        {GetAppText("instant_donate")}
        </div>
        <SvgComponent
              name={"Arrow"}
              className={`sm:w-6 sm:h-6 h-4 w-4`}
              // stroke={"#727272"}
              // fill={"none"}
            />
                    {/* <Arrow/> */}
                  
      </button>}

      {showModal && (
        // <div className="fixed inset-0  z-50  flex items-center  ">
          <div
        
            className={`bg-primary p-6 fixed ${language === "_ar" ? "sm:left-10 left-2 " : "sm:right-10 right-2"} sm:bottom-2 bottom-3 z-50 rounded-lg shadow-lg sm:w-[560px] w-[92vw] transition-opacity duration-300 ${
              isModalOpen  ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
          >
                 {/* <div className=" w-32 top-0 left-0">
     <Texture className="object-cover absolute w-32"/>
      </div> */}
      <div className="pb-6">
      <div className="z-20 pt-2.5 absolute">
          
            <div
              className="  text-xl cursor-pointer text-white"
              onClick={closeModal}
            >
        
              <SvgComponent
                      name={"Cross"}
                      className={"w-3 h-3"}
                      stroke={"white"}
                      fill={"none"}
                    />
            </div>
       
           
           </div>
           <div className={`font-bold flex ${language === "_ar" ? "justify-end":" pl-6"}  text-white text-F22`}>
        {GetAppText("instant_donate")}
        </div>
        </div>
            <QuickDonate data={details}/>
          </div>
        // </div>
      )}
</div>
    </div>
         
        </div>
       {data?.urgent_projects?.length>0&& <div className="lg:px-20 md:px-14  md:py-16 w-full">
          <UrgentProjects data={data?.urgent_projects} loading={loading}/>
        </div>}
        <div>
          <Achievement />
        </div>
      {data?.end_soon_projects?.length>0 &&  <div className="">
          <ProjectsEndingSoon data={data?.end_soon_projects} loading={loading}/>
        </div>}
        <div className="md:space-y-16 space-y-10">
       {data?.stories?.length>0 && <AboutDonations data={data?.stories} loading={loading}/>}
        
     {data?.news?.length>0&&   <div className="lg:px-16 md:px-10 px-6">
          <HomeNews data={data?.news} loading={loading}/>
        </div>}
        </div>
      </div>
    </div>
  );
};

export default Home;
