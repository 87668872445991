
import React, { useEffect, useState } from "react";

import image from "../../../../assets/images/10.png"
import { GetAppText } from "../../../../utils";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { apiRoutes } from "../../../../apis/routes";
import { getRequest } from "../../../../apis/methods";
import { Loader } from "../../../components/Loader";
import { Image_URL } from "../../../../utils/BaseUrl";
import Pagination from "../../../components/Pagination";
import { ButtonComponent } from "../../../components/ButtonComponent";
const StoriesListing = () => {
  const language = useSelector((state) => state.language.value);
  const [isHovered, setIsHovered] = useState(false); 
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState('');
const [loading,setLoading]=useState(false)
const [showLoading,setShowLoading]=useState(false)
const [showPage, setshowPage] = useState(1);
const[data,setData]=useState('')
        useEffect(()=>{
          fetchData()
        },[currentPage])

        const fetchData = async () => {
          setLoading(true)
          try {
            const response = await getRequest(`${apiRoutes.get_stories}?page=${currentPage}`); // Replace with your endpoint
      if(response.status==="success")
         { 
          setLoading(false)
          setData(response?.data?.data)
          setLastPage(response?.data?.last_page)
        
        }
        else{
          setLoading(false)
        }
          } catch (error) {
            setLoading(false)
           
          }
        };


        const fetchShowMoreData = async (page) => {
          setShowLoading(true);
          try {
            const response = await getRequest(`${apiRoutes.get_stories}?page=${page}`);
            if (response.status === "success") {
              setShowLoading(false);
              setshowPage(showPage + 1); 
              // Append the new data to the existing data
              setData((prevData) => [...prevData, ...response?.data?.data]);
              setLastPage(response?.data?.last_page);

            } else {
              setShowLoading(false);
            }
          } catch (error) {
              setShowLoading(false);
            console.error("Error fetching data:", error);
          }
        };

const navigate = useNavigate();

const handlePageChange = (page) => {
 
  setCurrentPage(page);
};

const handleShowMore = () => {
  if (showPage < lastPage) {
 
    const page=showPage + 1
    fetchShowMoreData(page)
    // Increment the current page
  }
};


const handleProjectClick = (id) => {
  
  navigate(`/story/${id}`);
};

    return (
        <div>
             <div className="relative ">
      <img src={image} className="sm:h-[400px] h-[560px] object-cover w-full"  alt=""/>
      {/* <div className="flex justify-between">
      <div
            className={`sm:flex hidden items-center  z-30 absolute bottom-10  ${language === "_ar"
                ? "flex-row-reverse right-14"
                : "left-14"
              } text-white text-F40 font-bold `}
          >{GetAppText("project_story")}</div>
          </div> */}
          {/* <div
            className={`sm:flex hidden items-center  z-30 absolute bottom-10 ${language === "_ar"
                ? "flex-row-reverse left-14"
                : "right-14"
              } text-white text-F40 font-bold `}
          >{GetAppText("contribute")}</div> */}
           {/* <div
            className={`flex sm:hidden items-center  z-30 absolute bottom-5  ${language === "_ar"
                ? "flex-row-reverse right-5"
                : "left-5"
              } text-white text-F28 font-bold `}
          >{GetAppText("project_story")}<br/>{ GetAppText("contribute")}</div> */}

<div className="absolute z-30 w-full sm:bottom-14 bottom-5">
<div className={`sm:flex justify-between text-white xl:text-F40 lg:text-F38 md:text-F34 sm:text-F30 text-F28 font-bold ${language === "_ar"
                ? "flex-row-reverse "
                : ""
              } w-full md:px-8 px-5`}>
      <div
            className={`flex  items-center `}
          >{GetAppText("project_story")}</div>
                 <div
            className={`flex  items-center    `}
          >{GetAppText("contribute")}</div>
          </div>
          </div>
       </div>
   


      <div className="sm:px-20 px-5 sm:py-12 py-10 w-full space-y-6">
        <div className="flex flex-wrap gap-6 justify-center items-center">
{/* {images&&images?.map((item)=>(
<img key={item?.id} src={item?.image} className="rounded-lg cursor-pointer" alt=""
   onClick={() => handleImageClick(item?.id)}
/>

))} */}
     { loading ?
         <div className="flex sm:h-[240px] h-[200px] w-full justify-center items-center">
         <Loader/>
         </div>
         
         : 
        
         data?.length>0? 
         <div className={`flex flex-wrap sm:gap-6 gap-1.5 justify-center items-center ${language==="_ar"?"-justify-end":""}`}>
 {         data.map((item,index)=>
(
//   <div className={`h-[231px]  w-[410px] !rounded-lg `}>
//     <img  
//     src={`${Image_URL}${item?.image}`}
  
//     className= {`h-full w-full rounded-lg cursor-pointer`} alt=""
//     onClick={() => handleProjectClick(item?.id)}
//  />
//   {/* {item?.media?.map((items,index)=>(
//   (  index === 0 &&  <img key={index?.id} src={items?.thumbnail} className= {`h-full w-full rounded-lg cursor-pointer`} alt=""
//     onClick={() => handleProjectClick(item?.id)}
//  />)
//   ))} */}
//   </div>
 <div
     key={index}
      onMouseEnter={() => setIsHovered(true)}  // Set hover state to true on hover
      onMouseLeave={() => setIsHovered(false)} // Set hover state to false when not hovering
    className={`${isHovered && 'relative group'} rounded-lg bg-red-300 font-semibold flex justify-center items-center cursor-pointer sm:h-[240px] sm:w-[410px] w-[180px] h-[130px]`} 
   
    onClick={() => handleProjectClick(item?.slug)}
    >
        {/* Image */}
        <img
         className="rounded-lg sm:h-[240px] w-[180px] h-[130px] object-cover sm:w-[410px]" alt="logo"
          // className="rounded-t-[8px] h-52 object-cover w-full"
          src={`${Image_URL}${item?.image}`}
        />

        {/* Overlay with black blur and title (hidden by default) */}
        {isHovered && (
        <div
          className={`absolute inset-0 z-20 bg-black bg-opacity-50 opacity-0 ${isHovered && 'group-hover:opacity-100'}  flex justify-center items-center transition-opacity duration-300 rounded-t-[8px]`}
          style={{ backdropFilter: `blur(1px)` }}
        >
          <h1 className="text-white font-bold sm:text-F20 text-F12">
            {language === "_ar" ? item?.title_ar : item?.title}
          </h1>
        </div>)}
      </div>
//   <div key={item?.id} className="rounded-lg border border-borderColor font-semibold flex justify-center items-center cursor-pointer h-[231px] w-[410px]" 
//    onClick={() => handleProjectClick(item?.id)}>
//     {language === "_ar"?item?.title_ar:item?.title}
// </div>

))  }
</div>
:
(!loading && <div className="flex w-full justify-center text-F20 font-semibold items-center">
          {GetAppText("no_data")}
              </div>)}
</div>
<div className="sm:flex hidden justify-center w-full  pt-4">
          <Pagination currentPage={currentPage} lastPage={lastPage} onPageChange={handlePageChange} />
         
          </div>
       {showPage!==lastPage &&   <div className="sm:hidden flex justify-center font-medium text-F18 w-full  pt-2">
          <ButtonComponent loading={showLoading} disabled={showLoading || showPage===lastPage} onClick={()=>{handleShowMore()}} styles={"!w-full !py-2.5  !border-2 !text-lightTextColor !border-borderColor"}  title={GetAppText("show_more")} type="transparent"/>
         
          </div>}
      </div>
 
      </div>
    );
  };
  
  export default StoriesListing;
  