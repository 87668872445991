import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const InfoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    infoHandler: (state, action) => {

      state.value = action.payload;
    },
  },
});

export const { infoHandler } = InfoSlice.actions;

export default InfoSlice.reducer;
