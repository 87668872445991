import React from "react";
import { SeparatorComponent } from "../Separator";
import SvgComponent from "../SvgComponent";
import { GetAppText } from "../../../utils";
import { useSelector } from "react-redux";
import news1 from "../../../assets/images/news1.png";
import { Image_URL } from "../../../utils/BaseUrl";
export const NewsCardComponent = ({ data,onClick }) => {
  const language = useSelector((state) => state.language.value);

const descript=(text,maxLength=140)=>{
if(text)

{  if(text.length > maxLength){
return(text.substring(0, maxLength) + " ....")
  }
  else{
return (text)
  }}
}

  return (
    <div className="xl:w-[390px] w-[360px]">
      <div className="h-[240px] rounded-t-lg">
        <img 
     
        src={`${Image_URL}${data?.image}`}
        className="w-full object-cover  rounded-t-lg h-[240px]" />
      </div>
      <div className="border border-borderColor border-t-0 rounded-b-lg">
        <div className="py-5 ">
          <div className="px-6">
          <div className="space-y-3">
            <h1 className={`text-black text-F20 line-clamp-1 font-bold ${language === "_ar" ?"text-right":"text-left"}`}>
              {language === "_ar" ? data.title_ar:data.title}
            </h1>
            <h1 className={`text-primary text-F16 ${language === "_ar" ?"text-right rtl:text-right":"text-left"} -text-nowrap 
            md:line-clamp-3 line-clamp-3
            -overflow-hidden  -text-ellipsis`}>
              {language === "_ar" ? descript(data.short_description_ar):descript(data.short_description)}
              </h1>
          </div>
          </div>
          <div className="px-4">
          <SeparatorComponent className="!my-5" />
          <div
            className={`flex items-center justify-between ${
              language === "_ar" ? "flex-row-reverse" : ""
            }`}
          >
            <div
              className={`flex items-center space-x-1.5 ${
                language === "_en" ? "flex-row-reverse space-x-reverse" : ""
              }`}
            >
              <span className="text-F16 text-secondaryTextColor">
                {data?.posted_days_ago}
              </span>
             {data?.posted_days_ago&& <SvgComponent
                name={"Clock"}
                className={"w-5 h-5"}
                fill="none"
                stroke={"#727272"}
              />}
            </div>
            <div
              className={`flex items-center space-x-1.5 ${
                language === "_en" ? "flex-row-reverse space-x-reverse" : ""
              }`}
            >
              <SvgComponent
                name={language === "_ar" ? "ArrowLeft" : "ArrowRight"}
                className={"w-5 h-5"}
                fill="none"
                stroke={"black"}
              />
              <span onClick={onClick} className="text-F16 font-medium hover:underline hover:cursor-pointer">
                {GetAppText("read_more")}
              </span>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};
