import React, { useEffect, useState } from "react";
import image from "../../../assets/images/10.png";
import { GetAppText } from "../../../utils";
import { useSelector } from "react-redux";
import { apiRoutes } from "../../../apis/routes";
import { getRequest } from "../../../apis/methods";
import { Loader } from "../../components/Loader";
const TermsCondition = () => {
  const [loading,setLoading]=useState(false)
const[data,setData]=useState('')
useEffect(()=>{
  fetchData()
},[])

  const language = useSelector((state) => state.language.value);



  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await getRequest(`${apiRoutes.get_terms}`
       
        ); // Replace with your endpoint
if(response.status==="success")
   { 
    setLoading(false)
    setData(response?.data)

  }
  else{
    setLoading(false)
  }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="space-y-4">
      {/* <div className="relative">
        <img src={image} className="w-full h-[400px] object-cover" alt="" />
        <div
          className={`flex items-center z-40 bottom-24  absolute ${
            language === "_ar" ? "flex-row-reverse right-20" : "left-20"
          } text-white text-F40 font-bold `}
        >
          {GetAppText("terms_use")}
        </div>
      </div> */}
      <div className=" md:px-20 px-8 md:py-16 py-8 w-full space-y-6">
        <h1
          className={`flex items-center  ${
            language === "_ar" ? "flex-row-reverse " : ""
          } text-primary text-F24 font-bold `}
        >
          {GetAppText("terms_use_conditions")}
        </h1>
      {loading?  <div className="flex h-[400px] w-full justify-center items-center">
 <Loader/>
 </div>
 :
  data?.value_ar || data?.value ?  <div className={`${language === "_ar" ?"text-right":"text-left"} bg-white p-6 flex-row-reverse rounded-xl`} dangerouslySetInnerHTML={{ __html: language === "_ar" ? data?.value_ar:data?.value }}>

        </div>:
         <div className="bg-white space-y-10 p-6 text-F18 font-semibold justify-center h-[400px] flex items-center rounded-xl">
         {GetAppText("no_data")}
                </div>
        
        }
    
        {/* <div className="bg-white space-y-10 p-6 flex-row-reverse rounded-xl">
          {Aboutdata &&
            Aboutdata?.map((item) => (
              <div className="space-y-3" key={item?.id}>
                <h1
                  className={`flex items-center  ${
                    language === "_ar" ? "flex-row-reverse " : ""
                  } text-black text-F16 font-bold `}
                >
                  {item?.heading}
                </h1>
                <div
                  className={`flex items-center  ${
                    language === "_ar" ? "flex-row-reverse  text-right" : ""
                  } text-primary text-F16 font-medium`}
                >
                  {item?.para}
                </div>
              </div>
            ))}
        </div> */}
      </div>
    </div>
  );
};

export default TermsCondition;
