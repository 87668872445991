import React from "react";
import { useSelector } from "react-redux";
import { Loader } from "../Loader";
import { GetAppText } from "../../../utils";
export const ButtonComponent = ({
  title,
  type = "secondary",
  styles,
  svg = "",tooltip,isDonate=false,
  onClick,
  onClickSvg,
  isSvgLeft = false,
  disabled = false, // New prop for disabling the button
  loading = false, // New prop for showing loader
}) => {
  const language = useSelector((state) => state.language.value);

  function getButtonClasses() {
    if (type === "secondary") {
      return "bg-secondary text-white border-secondary border-[2px]";
    } else if (type === "primary") {
      return "bg-primary text-white border-primary border-[2px]";
    } else if (type === "transparent") {
      return "bg-transparent border-white border-[2px] text-white";
    } else if (type === "secondary_transparent") {
      return "bg-transparent border-primary border-[2px] text-primary font-semibold";
    }
    else if (type === "danger") {
      return "bg-errorColor border-[2px] border-errorColor text-white";
    }
  }
  return (
    <button
    title={tooltip}
      onClick={onClick}
      className={` px-3 py-2.5 lg:text-F20 md:text-F19 text-F18   uppercase ${
        svg && title ? "space-x-3" : "space-x-0"
      } rounded-md flex  ${
        !isSvgLeft && language === "_ar"
          ? ""
          : "flex-row-reverse space-x-reverse"
      }  justify-center ${!disabled && !isDonate && "hover:opacity-85"} ${disabled || loading ? "opacity-60":""} items-center font-semibold ${styles} ${getButtonClasses()}`}
      disabled={disabled || loading} // Disable the button
    >
       {loading ? (
        <div className={`flex ${language === "_ar"
          ?"flex-row-reverse space-x-reverse":""} items-center space-x-3`}>
       <Loader style="!w-5 !h-5"/>
 { title &&     <div className={`font-semibold space-x-2 text-F16 flex ${language === "_ar"
          ?"flex-row-reverse space-x-reverse":""}`} >
      <div>{GetAppText("loading")}</div>  <div>.....</div> 
      </div>}
       </div>
      ) : (
        <>
          <div onClick={onClickSvg}>{svg && svg}</div>
          <div>{title}</div>
        </>
      )}
      {/* <div>{svg && svg}</div>
      <div>{title}</div> */}
    </button>
  );
};
