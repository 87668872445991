import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const ModalSlice = createSlice({
  name: "openModel",
  initialState,
  reducers: {
    modalHandler: (state, action) => {
   
      state.value = action.payload;
    },
  },
});

export const { modalHandler } = ModalSlice.actions;

export default ModalSlice.reducer;
