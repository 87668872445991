import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: localStorage.getItem("language") || "_ar",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    switchLanguage: (state, action) => {
     
      state.value = action.payload;
      localStorage.setItem("language", action.payload);
    },
  },
});

export const { switchLanguage } = languageSlice.actions;

export default languageSlice.reducer;
