import React, { useEffect, useState } from "react";

import { useParams } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { GetAppText } from "../../../utils";

import { ButtonComponent } from "../../components/ButtonComponent";
import { useNavigate } from 'react-router-dom';
import SvgComponent from "../../components/SvgComponent";
import { SeparatorComponent } from "../../components/Separator";
import { apiRoutes } from "../../../apis/routes";
import { getRequest } from "../../../apis/methods";
import { Loader } from "../../components/Loader";
import { NewsCardComponent } from "../../components/NewsCardComponent";
import { Image_URL } from "../../../utils/BaseUrl";
import { modalHandler } from "../../../store/slices/modalSlice";
import { handleSuccess } from "../../components/Toast/toastHelper";
import { ToastContainer } from "react-toastify";

const News = () => {
 
const { id } = useParams();
const navigate = useNavigate();
const [loading,setLoading]=useState(false)
const[data,setData]=useState('')
const [storyloading,setStoryLoading]=useState(false)
const[storyData,setstoryData]=useState('')
const[otherData,setOtherData]=useState('')
const isOpen = useSelector((state) => state.openModel.value);
const dispatch = useDispatch();
        useEffect(()=>{
          fetchData()
          
        },[id])

        useEffect(()=>{
       
           if(isOpen?.type==="share"){
            handleSuccess(isOpen?.msg,language)
          }
       
        },[isOpen])
      

        // useEffect(()=>{
        //   otherStoriesData()
        // },[])
        const fetchData = async () => {
          setLoading(true)
          try {
            const response = await getRequest(`${apiRoutes.get_newsDetails}?link=${id}`); // Replace with your endpoint
      if(response.status==="success")
         { 
          setLoading(false)
          setData(response?.data?.new)
          setOtherData(response?.data?.similar_news)
          }
        else{
          setLoading(false)
        }
          } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
          }
        };



        const language = useSelector((state) => state.language.value);


          const handleProjectClick = (id) => {
         
            navigate(`/news/${id}`);
          };
          

          const handle_share=()=>{
            const currentURL = window.location.href;
         
          const fullLink=`${currentURL}`
              // Copy the link to the clipboard
              navigator.clipboard.writeText(fullLink).then(() => {
                dispatch(modalHandler({type:"share",msg:GetAppText("link")}));
                setTimeout(()=>{
                  dispatch(modalHandler(""));
                },100)
              });
          
          }
          
  return (
    <div className="md:px-20 px-12 py-12 w-full space-y-10">
      <ToastContainer/>
{      loading?    <div className="flex justify-center items-center h-[540px]">
      <Loader/>
      </div>:
      
      <div
        className={` ${
          language === "_ar" ? " " : " space-x-reverse "
        }  w-full justify-between sm:space-y-6`}
      >
        <div className="flex sm:flex-col flex-col-reverse sm:space-y-6 space-y-6 space-y-reverse">
         <div
            className={`flex sm:flex-row flex-col-reverse sm:pb-0 pb-4 sm:space-y-0 space-y-3 space-y-reverse ${
              language === "_ar" ? "sm:flex-row-reverse items-end " : ""
            } sm:items-center sm:justify-between`}
          >
            <h1 className={`font-bold ${
              language === "_ar" ? "text-right":""} text-F24 `}>{language === "_ar" ?data?.title_ar:data?.title}</h1>
            <ButtonComponent
                  onClick={()=>{handle_share()}}
                  tooltip={GetAppText("link_share")}
              title={GetAppText("sharing")}
              svg={
                <SvgComponent
                  name={"ShareSvg"}
                  className={"w-4 h-4"}
                  stroke={"black"}
                  fill={"none"}
                />
              }
              styles={"!py-2 w-fit !px-3 !text-black !text-F16"}
              type="secondary_transparent"
            />
          </div>
<div className="w-full ">
        <img src={`${Image_URL}${data?.image}`} className="h-[550px] object-cover w-full rounded-lg" alt="logo" />
        </div>

        </div>
        <div className={`${language === "_ar" ?"text-right":"text-left"}`} dangerouslySetInnerHTML={{ __html: language === "_ar" ? data?.description_ar:data?.description }}>

      </div>
        {/* <div className="w-full text-primary  pt-8 pb-4">
         
          <div className={`font-normal ${
          language === "_ar" ? "text-right" : "text-left "} text-F16  `}>{language === "_ar" ?data?.description_ar:data?.description}</div>

        </div> */}
      </div>
      }
<SeparatorComponent  />
<div  className="space-y-8" >

<h1 className={` flex ${
          language === "_ar" ? "flex-row-reverse " : " "}
        font-semibold text-primary text-F30 `}>{GetAppText("Other_news")}</h1>

    <div className={`flex ${
          language === "_ar" ? "flex-row-reverse " : " "} flex-wrap gap-6 justify-start items-center`}>
{/* {images&&images?.map((item)=>(
<img src={item?.image} className="rounded-lg" alt="logo"/>

))} */}
     { loading ?
         <div className="flex h-[300px] w-full justify-center items-center">
         <Loader/>
         </div>
         : 
    
         otherData?.length>0? 
         
         otherData.map((item,index)=>
(

  <NewsCardComponent  onClick={()=>{handleProjectClick(item?.slug)}} key={index}  data={item}/>

))  
:
(!loading && <div className="flex w-full h-[300px] justify-center text-F20 font-semibold items-center">
             {GetAppText("no_data")}
              </div>)}
</div>
</div>
  </div>
  );
};

export default News;
