// apiMethods.js
import axiosInstance from "../interceptor";

// GET method
export const getRequest = async (url, data = {}) => {
  try {
    const response = await axiosInstance.get(url, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// POST method
export const postRequest = async (url, data = {}) => {
  try {
    const response = await axiosInstance.post(url, data);
 
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response; // Return the 400 error response like a success case
    }

    throw error;
  }
};

//delete Method
export const deleteRequest = async (url, data = {}) => {
  try {
    const response = await axiosInstance.delete(url, { data });
 
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response; // Return the 400 error response like a success case
    }

    throw error;
  }
};
