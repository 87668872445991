import { useState, useRef, useEffect } from 'react';
import { GetAppText } from "../../../utils";
import { useSelector } from "react-redux";
import SvgComponent from '../SvgComponent';
import { ReactComponent as SquareCheckboxFill } from "../../../assets/svgs/squareCheckBox.svg";
import { Image_URL } from '../../../utils/BaseUrl';


import "../../../App.css"
const Dropdown = ({ isOpen, options,governet, area,setIsDropdownOpen,handleSelect,mainStyle ,phone,category,sort,selectedValues ,countryName,global}) => {

  const dropdownRef = useRef(null);
  const language = useSelector((state) => state.language.value);

  const [searchText, setSearchText] = useState("");
  // Close the dropdown if the user clicks outside of it
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
    }
  };
  // Ensure options is an array, even if undefined or null
  const safeOptions = Array.isArray(options) ? options : [];
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

 // Filter options based on the search text
 const filteredOptions = safeOptions?.filter(option => {
  const name = language === "_ar" ? option.name_ar || option.title_ar : option.name || option.title;
  return name?.toLowerCase()?.includes(searchText?.toLowerCase());
});

  return (
    <div className="relative" ref={dropdownRef}>
      {isOpen && (
        <div className={`absolute ${mainStyle} ${language === "_ar" ? "right-0 " : "left-0"} z-40  mt-0.5 w-36  dropdown  overflow-y-auto  bg-white border border-gray-200 rounded-lg shadow-lg`}>
         {/* Search Input */}
     {  area&&  <div className="p-2 px-2">
          

<div className=" w-full">
              <label
                htmlFor="default-search"
                className="mb-2 text-F11 font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div className="relative">
                <div
                  className={`absolute inset-y-0 flex items-center pointer-events-none ${
                    language === "_ar" ? "end-0 ps-3 pr-1" : "start-0 ps-3"
                  }`}
                >
                  <SvgComponent
                    name={"Search"}
                    className={`w-3 h-3`}
                    stroke={"#727272"}
                    fill={"none"}
                  />
                </div>
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  id="default-search"
                  className={`block w-full p-2 py-1.5 placeholder:text-F11 text-F12 placeholder:text-lightTextColor focus:outline-none focus:border-borderColor focus:ring-0 border-2 border-borderColor rounded-lg ${
                    language === "_ar" ? "pe-5 text-right" : "ps-7"
                  }`}
                  placeholder={GetAppText("search_here")}
                />
              </div>
            </div>
          </div>}
       
          {filteredOptions?.length>0? filteredOptions?.map((option, index) => (
            <div className='flex items-center'  key={index}>
        

            <button
             
              onClick={() => {handleSelect(option)}}
              // href={option.link || "#"}
              className={`${language === "_ar" ? "text-right flex-row-reverse " : "text-left"} flex  items-center  px-4 gap-2 w-full py-3 text-gray-700 -rounded-lg hover:bg-gray-100`}
            >
                {area || category ? (
                  <div>
                    {selectedValues.some((val) => val.id === option.id) ? (
                      <SquareCheckboxFill  />
                    ) : (
                      <div className="w-5 h-5 border border-gray-300 rounded-md"></div>
                    )}
                  </div>
                ):''}
            {  governet&&   <SvgComponent
                      name={
                        selectedValues.id === option.id
                          ? "CheckboxFill"
                          : 
                          "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />}
                    {global ?  
            <img
         className=" w-10 h-10 rounded-md" alt="logo"
          // className="rounded-t-[8px] h-52 object-cover w-full"
          src={`${Image_URL}${option?.image}`}
        />
      :""
      }
                 <div className={` flex items-center justify-between  w-full ${language === "_ar" ? "flex-row-reverse " : "text-left"}`}>
             <div className={`${global && 'line-clamp-1'}`}>
              { phone ? option.phone_code : countryName || sort ||governet || area?`${language === "_ar" ?option?.name_ar:option?.name}`  : category || global? `${language === "_ar" ?option.title_ar:option.title}`:`${language === "_ar" ?option.key_ar:option.key}`}
              </div>
            {phone || countryName ?  
            <img
         className=" w-7 h-5" alt="logo"
          // className="rounded-t-[8px] h-52 object-cover w-full"
          src={require(`../../../assets/countries/${option.icon}`)}
        />
      :""
      }
   
              </div>
            </button>
            </div>
          ))
          :<div className={`flex  ${area?'py-12 items-start':' items-center -mt-2 py-8'} justify-center text-F13  font-semibold h-full`}>
          {GetAppText("no_data")}
          </div>
          }
        </div>
      )}
    </div>
  );
};

export default Dropdown;
