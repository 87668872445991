import React from "react";
import { GetAppText } from "../../../../utils";
import { useSelector } from "react-redux";

export const Achievement = () => {
  const language = useSelector((state) => state.language.value);

  const settingData = useSelector((state) => state?.setting?.value); 
   const project_counter = settingData?.data?.find(
    item => item.name === 'DONATION_PROJECTS_COUNTER',
  );
  const benefit_counter = settingData?.data?.find(
    item => item.name === 'BENEFICIARIES_COUNTER',
  );
  const transaction_counter = settingData?.data?.find(
    item => item.name === 'DONATION_TRANSACTIONS_COUNTER',
  );


  const formatToK = (numberString) => {
    const number = parseInt(numberString, 10);
if(number >= 1000)
{return <div className={`flex  space-x-2 ${language === "_ar"?"flex-row-reverse space-x-reverse":""}`}>
<div>{(number / 1000).toFixed(1)}</div>
<div>{language === "_en"?'K':'الف'}</div>
</div>}
else{
 return number.toString()
}

    // return <div>{number >= 1000 ? (number / 1000).toFixed(1) + `${language === "_en"?'K':'الف'}` : number.toString()}</div>
  };


  const achievementData = [
    {
      value: project_counter?.value,
      heading: GetAppText("donation_project"),
    },
    {
      value: formatToK(benefit_counter?.value),
      heading: GetAppText("beneficiary"),
    },
    {
      value: transaction_counter?.value,
      heading: GetAppText("donation_process"),
    },
  ];
  return (
    <div className="w-full h-full bg-custom-gradient-background relative sm:p-20 px-6 py-10  flex justify-center items-center">
      <div className="absolute top-0 right-0">
        <img className="object-cover" src={require("../../../../assets/images/Vector.png")} alt="logo" />
      </div>
      <div className=" flex items-center justify-center">
        <div className="grid justify-items-stretch md:space-y-16  space-y-10">
          <div className=" space-y-4 w-[85%] justify-self-center">
            <h1 className="text-white md:text-F32 sm:text-F29 text-F28 font-bold text-center">
              {GetAppText("stride_to_achieve")}
            </h1>
            <h1 className="text-white md:text-F28 sm:text-F24 text-F20 text-center">
              {GetAppText("achievement_desc")}
            </h1>
          </div>
          <div className="md:grid md:grid-cols-3 md:gap-24 md:px-0 px-4 md:space-y-0 space-y-6  md:w-[85%] w-[90%] justify-self-center">
            {(language === "_en"
              ? achievementData.slice().reverse()
              : achievementData
            ).map((item, index) => (
              <div
                className="flex flex-col  justify-center items-center"
                key={index}
              >
                <h1 className={`text-secondary whitespace-nowrap  sm:text-F56  text-F48  font-bold`}>
                  {item?.value}
                </h1>
                <h1 className="text-white whitespace-nowrap md:text-F24 text-F20">{item.heading}</h1>
                <div className="sm:h-2 h-2 w-full bg-white  md:mt-6 mt-4"  />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
