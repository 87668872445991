
import React, { useEffect, useState } from "react";

import image from "../../../../assets/images/10.png"
import { GetAppText } from "../../../../utils";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { apiRoutes } from "../../../../apis/routes";
import { getRequest } from "../../../../apis/methods";
import { Loader } from "../../../components/Loader";
import { Image_URL } from "../../../../utils/BaseUrl";
import Pagination from "../../../components/Pagination";
import { ButtonComponent } from "../../../components/ButtonComponent";
import { NewsCardComponent } from "../../../components/NewsCardComponent";
const NewsListing = () => {
  const language = useSelector((state) => state.language.value);
  const [isHovered, setIsHovered] = useState(false); 
  const [currentPage, setCurrentPage] = useState(1);
  const [showPage, setshowPage] = useState(1);
  const [lastPage, setLastPage] = useState('');
  const [showLoading,setShowLoading]=useState(false)
const [loading,setLoading]=useState(false)
const[data,setData]=useState('')
        useEffect(()=>{
          fetchData()
        },[currentPage])

        const fetchData = async () => {
          setLoading(true)
          try {
            const response = await getRequest(`${apiRoutes.get_news}?page=${currentPage}`); // Replace with your endpoint
      if(response.status==="success")
         { 
          setLoading(false)
          setData(response?.data?.data)
          setLastPage(response?.data?.last_page)
          
        }
        else{
          setLoading(false)
        }
          } catch (error) {
            setLoading(false)
           
          }
        };


        const fetchShowMoreData = async (page) => {
            setShowLoading(true);
            try {
              const response = await getRequest(`${apiRoutes.get_news}?page=${page}`);
              if (response.status === "success") {
                setShowLoading(false);
                setshowPage(showPage + 1); 
                // Append the new data to the existing data
                setData((prevData) => [...prevData, ...response?.data?.data]);
                setLastPage(response?.data?.last_page);
             
              } else {
                setShowLoading(false);
              }
            } catch (error) {
                setShowLoading(false);
              console.error("Error fetching data:", error);
            }
          };

const navigate = useNavigate();

const handlePageChange = (page) => {
 
  setCurrentPage(page);
};

const handleShowMore = () => {
    if (showPage < lastPage) {
       
      const page=showPage + 1
      fetchShowMoreData(page)
      // Increment the current page
    }
  };

const handleProjectClick = (id) => {
  
  navigate(`/news/${id}`);
};


    return (
        <div>
             <div className="relative">
      <img src={image} className="sm:h-[400px] h-[560px] object-cover w-full"  alt=""/>
      <div className="flex justify-between">
      <div
            className={`flex  items-center  z-30 absolute bottom-10  ${language === "_ar"
                ? "flex-row-reverse sm:right-14 right-6"
                : "sm:left-14 left-6"
              } text-white text-F40 font-bold `}
          >  {GetAppText("news")}</div>
          </div>
    
       </div>




      <div className="xl:px-15 lg:px-10 px-5 sm:py-12 py-10 w-full space-y-6">
        <div className="flex flex-wrap gap-6 justify-center items-center">

     { loading ?
         <div className="flex sm:h-[240px] h-[200px] w-full justify-center items-center">
         <Loader/>
         </div>
         
         : 
        
         data?.length>0? 
         <div className={`flex flex-wrap sm:gap-6 gap-5 justify-center items-center ${language==="_ar"?"-justify-end":""}`}>
 {         data.map((item,index)=>


    (<NewsCardComponent  onClick={()=>{handleProjectClick(item?.slug)}} key={index}  data={item}/>))  


 }
</div>
:
(!loading && <div className="flex w-full justify-center text-F20 font-semibold items-center">
          {GetAppText("no_data")}
              </div>)}
</div>
<div className="sm:flex hidden justify-center w-full  pt-4">
          <Pagination currentPage={currentPage} lastPage={lastPage} onPageChange={handlePageChange} />
         
          </div>
        {showPage!==lastPage &&  <div className="sm:hidden flex justify-center font-medium text-F18 w-full  pt-2">
          <ButtonComponent disabled={showLoading || showPage===lastPage} loading={showLoading}  onClick={handleShowMore} styles={"!w-full !py-2.5  !border-2 !text-lightTextColor !border-borderColor"}  title={GetAppText("show_more")} type="transparent"/>
         
          </div>}
      </div>
 
      </div>
    );
  };
  
  export default NewsListing;
  