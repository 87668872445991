import React, { useState } from "react";
import { ButtonComponent } from "../../../../components/ButtonComponent";
import { GetAppText } from "../../../../../utils";
import { InputComponent } from "../../../../components/InputComponent";

import { StyleClass } from "../../../../../utils";
import { useAuth } from "../../../../../context/authContext";
import { getRequest } from "../../../../../apis/methods";
import { apiRoutes } from "../../../../../apis/routes";
import { modalHandler } from "../../../../../store/slices/modalSlice";
import { useSelector,useDispatch } from "react-redux";
export const AccountSettings = () => {

  const { logout } = useAuth(); 
  const language = useSelector((state) => state.language.value);
  const textAlignStyle = language === "_ar" ? "right" : "left";
  const[loading,setLoading]=useState(false)
  const dispatch = useDispatch();
  const delete_account = async () => {
    setLoading(true)
    try {
      

      const response = await getRequest(apiRoutes.delete_account); // Replace with your endpoint

      if (response.status==="success") {
        let error=GetAppText("deleted_account")
       logout()
       dispatch(modalHandler({ type: "toast", msg:error}));


    setTimeout(()=>{
        dispatch(modalHandler(""));
      },[500])
      }
    } catch (error) {
      console.error("Error fetching device info:", error);
    }
    setLoading(false)
  };
  // const [currentPassword, setCurrentPassword] = useState("");
  // const [newPassword, setNewPassword] = useState("");
  // const [confirmNewPassword, setConfirmNewPassword] = useState("");

  // const handleCurrentPasswordChange = (e) => setCurrentPassword(e.target.value);
  // const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  // const handleConfirmNewPasswordChange = (e) =>
  //   setConfirmNewPassword(e.target.value);

  return (
    <div className="w-full sm:space-y-6 space-y-4 h-full">
      <h1
        className={`text-primary text-F24 font-bold ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
        {GetAppText("account_settings")}
      </h1>
    
        <div className="space-y-4">
          {/* <div className="bg-white p-6 rounded-lg">
            <div className="space-y-5">
            <h1
                className="text-secondaryTextColor text-F16 pb-2 font-semibold block"
                style={{
                  textAlign: textAlignStyle,
                }}
              >
                {GetAppText("change_password")}
              </h1>
              <div
                className={`flex w-full ${
                  language === "_ar" ? "justify-end" : ""
                }`}
              >
                <div
                  className={`w-6/12 ${language === "_ar" ? "pl-2" : "pr-2"}`}
                >
                  <div className="space-y-2 w-full">
                    <span
                      className="text-black text-F14 block"
                      style={{
                        textAlign: textAlignStyle,
                      }}
                    >
                      {GetAppText("current_password")}
                    </span>
                    <InputComponent
                      input_style={"!py-3"}
                      value={currentPassword}
                      onChange={handleCurrentPasswordChange}
                    />
                  </div>
                </div>
              </div>
              <div className={`flex items-center space-x-4 ${StyleClass()}`}>
                <div className="space-y-2 w-full">
                  <span
                    className="text-black text-F14 block"
                    style={{
                      textAlign: textAlignStyle,
                    }}
                  >
                    {GetAppText("new_password")}
                  </span>
                  <InputComponent
                    input_style={"!py-3"}
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                  />
                </div>
                <div className="space-y-2 w-full">
                  <span
                    className="text-black text-F14 block"
                    style={{
                      textAlign: textAlignStyle,
                    }}
                  >
                    {GetAppText("confirm_new_password")}
                  </span>
                  <InputComponent
                    input_style={"!py-3"}
                    value={confirmNewPassword}
                    onChange={handleConfirmNewPasswordChange}
                  />
                </div>
              </div>
              <div className={`text-black text-F14 ${language === "_ar" ? "text-right" : "text-left"}  hover:underline cursor-pointer`}>
              {GetAppText("forgotPassword")}
            </div>
              <div
                className={`flex  w-full ${
                  language === "_en" ? "justify-end" : ""
                }`}
              >
                <div>
                  <ButtonComponent
                    type="secondary"
                    title={GetAppText("save_changes")}
                    styles={" !px-6 !text-F18"}
                   
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div className="bg-white p-6 rounded-lg">
            <div className="sm:space-y-6 space-y-4">
              <h1
                className="text-secondaryTextColor text-F16 font-semibold block"
                style={{
                  textAlign: textAlignStyle,
                }}
              >
                {GetAppText("delete_account")}
              </h1>
              <div className="space-y-5">
                <span
                  className="text-black text-F15 block"
                  style={{
                    textAlign: textAlignStyle,
                  }}
                >
                  {GetAppText("delete_account_note")}
                </span>
                <div
                  className={`flex ${language === "_ar" ? "justify-end" : ""}`}
                >
                  <ButtonComponent
                  loading={loading}
                  disabled={loading}
                    type="danger"
                    title={GetAppText("delete_account")}
                    styles={"!text-F18 !px-6 "}
                    onClick={() => delete_account()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
     
    </div>
  );
};
