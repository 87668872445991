import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const SideBarSlice = createSlice({
  name: "selectedOption",
  initialState,
  reducers: {
    switchTabHandler: (state, action) => {
    
      state.value = action.payload;
    },
  },
});

export const { switchTabHandler } = SideBarSlice.actions;

export default SideBarSlice.reducer;
