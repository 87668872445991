export default {
  login: "تسجيل الدخول",
  email: "البريد الإلكتروني",
  password: "كلمة المرور",
  forgotPassword: "نسيت كلمة المرور؟",
  createNewAccount: "إنشاء حساب جديد",
  home: "الرئيسية",
  donationProjects: "مشاريع التبرع",
  about_us: "نبذة عنا",
  technical_support: "الدعم الفني",
  tagLine: "بوابة التبرعات الرسمية للأوقاف السنية",
  download_app: "حمل التطبيق",
  donation: "تبرع",
  donation_basket: "سلة التبرعات",
  my_account: "حسابي",
  all_rights: "فعّلوا الخير. جميع الحقوق محفوظة",
  terms_use: "شروط الاستخدام",
  privacy_policy: "سياسة الخصوصية",
  official_donation_portal: "بوابة التبرعات الرسمية للأوقاف السنية",
  search_here: "ابحث هنا",
  days: "متبقي 12 يوم",
  school: "بناء مدرسة في المناطق الريفية",
  donated: "المتبرع به",
  goal: "الهدف",
  bhd: "د.ب",
  donate_now: "تبرع الآن",
  stride_to_achieve: "ما نسعى لتحقيقه",
  achievement_desc:
    "نسعى لتحسين حياة الأفراد والمجتمعات المحتاجة من خلال تنفيذ مشاريع ومبادرات خيرية مستدامة. نؤمن بأهمية التعليم، والرعاية الصحية، والتكافل الاجتماعي لبناء مستقبل أفضل.",
  donation_type: "نوع التبرع",
  education: "تعليم و صحة",
  condition: "الحالة",
  sort: "ترتيب حسب",
  closest_complete: "لأقرب للإكتمال",
  donation_project: "مشروع تبرع",
  beneficiary: "مستفيد",
  donation_process: "عملية تبرعية",
  sharing: "مشاركة",
  urgent: "عاجل",
  zakat: "تجوز الزكاة",
  close_target: "قريب من الهدف",
  remain_time: "الوقت المتبقي",
  domain: "المجال",
  educational: "تعليمي",
  max_limit: "الحد الأعلى للتبرع",
  residual: "المتبقي",
  add_cart: "اضف للسلة",
  donation_amount: "مبلغ التبرع",
  similar_projects: "مشاريع مشابهة",
  create_account: "إنشاء حساب",
  project: "المشروع",
  donation_value: "قيمة التبرع",
  total: "المجموع",
  complete_donation: "اتمام عملية التبرع",
  day: "يوم",
  payment_page: "صفحة الدفع",
  donation_summary: "ملخص عملية التبرع",
  edit: "تعديل",
  payment_method: "طريقة الدفع",
  debit_card: "بطاقة خصم",
  card_info:"معلومة",
  card_name: "الإسم على البطاقة",
  card_number: "رقم البطاقة",
  expiry_date: "تاريخ الإنتهاء",
  security_code: "رمز الأمان",
  continue: "بالمتابعة، فإنك توافق على",
  move_pay: "الانتقال للدفع",
  project_story: "لكل مشروع قصة",
  contribute: "ساهم في كتابتها",
  other_story: "قصص أخرى",
  all_stories: "كل القصص",
  read_more: "اقرأ المزيد",
  news: "أخبار",
  project_ending_soon: "مشاريع تنتهي قريباً",
  contribute_to_these_projects:
    "ساهم في هذه المشاريع قبل انتهاء فترة التبرعات، وساعدنا في تحقيق الأهداف ودعم المجتمعات المحتاجة.",
  show_more: "عرض المزيد",
  click_here: "اضغط هنا",
  urgent_projects: "مشاريع عاجلة",
  building_schools: "بناء مدرسة في المناطق الريفية",
  details: "التفاصيل",
  days_left: "متبقي 12 يوم",
  overview: "نبذة عن المؤسسة ورسالتها",
  // donation_amount: "مبلغ التبرع (د.ب)",
  name: "الإسم",
  nationality: "الجنسية",
  phone: "رقم الهاتف",
  personal_number: "الرقم الشخصي",
  confirm_password: "تأكيد كلمة المرور",
  password_valid_msg:
    "على الأقل 8 أحرف، وحرف كبير واحد وحرف صغير واحد على الأقل",
  complete_registration: "اكمل التسجيل",
  have_an_account: "لديك حساب؟ سجل الدخول",
  learn_more: "تعلم المزيد",
  donate: "تبرع",
  hope_grows: "الأمل ينمو في القرى الريفية",
  donation_successful: "تم التبرع بنجاح",
  payment_failed: "فشلت عملية الدفع",
  donation_success_msg:
    "شكرًا لك. لقد تم إتمام عملية التبرع بنجاح. نسأل الله أن يتقبل منك هذه الصدقة ويجعلها في ميزان حسناتك.",
  donation_failed_msg:
    "للأسف، لم تكتمل عملية التبرع. تحقق من معلومات الدفع أو اتصل بالبنك الخاص بك. نرجو المحاولة مرة أخرى لاحقًا",
  back_to_main: "العودة للرئيسية",
  share_project: "مشاركة المشروع",
  retry: "إعادة المحاولة",
  donate: "تبرع",
  hope_grows: "الأمل ينمو في القرى الريفية",
  personal_information: "المعلومات الشخصية",
  account_settings: "إعدادات الحساب",
  donation_record: "سجل التبرعات",
  sunni_endowments: "الأوقاف السنية",
  about_sunni_endowments: "نبذة عن الأوقاف السنية",
  terms_use_conditions: "شروط الاستخدام والأحكام",
  privacy_policy: "سياسة الخصوصية",
  help_support: "المساعدة والدعم",
  frequently_asked_questions: "الأسئلة الشائعة",
  contact_us: "تواصل معنا",
  contact_desc: "الوصول إلى موارد الدعم ومعلومات الاتصال للمساعدة.",
  logout: "تسجيل الخروج",
  download_app_now: "حمل التطبيق الآن",
  donate_easily_quickly: "تبرع بسهولة وسرعة من خلال هاتفك المحمول",
  test_user_name: "كريم عبدالله",
  our_website: "موقعنا",
  address:
    "مديرية الأوقاف السنية، الطابق 2، مبنى 46، طريق 3701، مجمع 337، المنامة، البحرين",
  get_direction: "احصل على الاتجاهات",
  nationality: "الجنسية",
  phone_number: "رقم الهاتف",
  personal_number_CPR: "(CPR) الرقم الشخصي",
  save_changes: "حفظ التغييرات",
  option: "الخيار",
  calculating: "... حساب الاتجاه",
  current_password: "كلمة المرور الحالية",
  new_password: "كلمة المرور الجديدة",
  confirm_new_password: "تآكيد كلمة المرور الجديدة",
  delete_account: "حذف الحساب",
  donation_date: "تاريخ التبرع",
  project_name: "اسم المشروع",
  delete_account_note:
    "يرجى العلم أن حذف حسابك سيؤدي إلى إزالة جميع بياناتك نهائيًا ولا يمكن التراجع عن هذه العملية. إذا كنت متأكدًا من رغبتك في المتابعة، انقر على زر الحذف أدناه.",
  project_status: "حالة المشروع",
  complete: "مكتمل",
  pending: "قيد التنفيذ",
  transaction_number : "رقم المعاملة",change_password:"تغيير كلمة المرور",
  enter_otp:"أدخل رمز التحقق OTP",verify_code:"تم ارسال رقم التحقق على البريد الإلكتروني",sure:"تأكيد",
  resend_code:"إعادة إرسال الرمز",resend_after:"إعادة إرسال الرمز بعد",enter_password:"أدخل كلمة المرور الجديدة",
  no_data:"... لم يتم العثور على محتوى",fields_req:"جميع الحقول مطلوبة",loading:"تحميل",
  logout_msg:"هل أنت متأكد أنك تريد تسجيل الخروج؟",cancel:"إلغاء",project_add:"تم إضافة المشروع إلى ",donation_bag:"سلة التبرعات",
  delete_msg:"هل أنت متأكد أنك تريد حذف هذا؟",delete:"يمسح",Other_news:"أخبار أخرى",instant_donate:"تبرع فوري",
  select_category:"اختر الفئة",choose_amount:"اختر المبلغ",link:"تم نسخ الرابط",link_share:"مشاركة الرابط",
  language:"تغيير اللغة",update:"تحديث",credit:"بطاقة إئتمان",apple:"أبل الدفع",projects:"المشاريع",masjid:"المساجد",
  area:'المنطقة',
  phone_not_valid: 'رقم الهاتف غير صالح',
  phone_already_exists: 'رقم الهاتف موجود بالفعل',
  email_must_be_valid: 'البريد الالكتروني يجب أن يكون صالح',
  phone_not_associated: 'رقم الهاتف هذا غير مرتبط بأي حساب',
  cprNumberNotComplete: 'يجب أن يتكون الرقم الشخصي من ',OtpVerify:'رمز التحقق غير صالح أو الهاتف غير موجود',
  updateProfile:"تم تحديث الملف الشخصي بنجاح",
  phoneUpdate:"تم تحديث الهاتف بنجاح",cpr_already_exists:"لقد تم بالفعل أخذ رقم CPR",
  email_already_exists:"لقد تم أخذ البريد الإلكتروني بالفعل",select_project:"اختر المشروع",
  added_cart:"تمت إضافة عنصر سلة التسوق بنجاح",
  limit_price_cart:"يجب ألا يتجاوز سعر السلعة المبلغ المتبقي",
  updated_cart:"تم تحديث عنصر سلة التسوق بنجاح",remaining:"متبقي",
  remove_cart:"تمت إزالة 8عنصر سلة التسوق بنجاح",
  donate_app:"تبرع بسهولة وسرعة من خلال هاتفك المحمول",
  deleted_account:".لقد تم حذف حسابك بنجاح",
  req_payment:"يرجى اختيار طريقة الدفع",req_price:"يجب إدخال السعر",
  amount_less:".يجب ألا يقل السعر عن 1",
  not_exceed_amount:".يمكنك التبرع بما يصل إلى 50,000 لكل معاملة",
    user_exits:".يبدو أن لديك بالفعل حساب معنا",
  user_exits_1:"هل ترغب في الدخول إلى حسابك؟",
  continue_guest:"الاستمرار كضيف",
  ph_limit:"رقم الهاتف يجب أن يكون على الأقل"
};
